import { AxiosError } from "axios";
import { ErrorResponse } from "./types/ApiResponse";
import { ErrorResponseData } from "./types/ErrorResponseData";

/**
 * Handles Axios errors and returns a standardized ErrorResponse.
 *
 * @param error - The AxiosError thrown during the API request.
 * @returns An ErrorResponse indicating the error state.
 */
const handleApiError = (error: AxiosError): ErrorResponse => {
  if (error.response) {
    // Server responded with a status other than 2xx
    const data = error.response.data as ErrorResponseData;
    return {
      status: "error",
      statusCode: error.response.status,
      message:
        data?.message || "An error occurred while processing your request.",
      data: error.response.data,
    };
  } else if (error.request) {
    // Request was made but no response received
    return {
      status: "error",
      statusCode: 0,
      message:
        "No response received from the server. Please check your network connection.",
    };
  } else {
    // Something happened in setting up the request
    return {
      status: "error",
      statusCode: 0,
      message: error.message,
    };
  }
};

export default handleApiError;
