import { useUserContext } from "context/UserContext";
import { useNavigation } from "./navigation/navigation";
import { useCartContext } from "context/CartContext";
import { useSeatContext } from "context/SeatContext";
import { useCallback, useMemo } from "react";
import { NavBarItem } from "components/Navbar/nav";
import { useI18n } from "i18n/I18nProvider";

export const useNavbar = () => {
  const {
    user: { access },
    setUser,
  } = useUserContext();

  const { seatName } = useSeatContext();

  const { t } = useI18n();

  const { prepCartItemDetail, resetPrepCartItemDetail } = useCartContext();

  const { navigateTo, goToLogin, goToDashboard } = useNavigation();

  const handleNavigation = (route: string) => {
    navigateTo(route);
  };

  const isAuthenticated = () => {
    return access;
  };

  // Calculate the total number of items in the cart
  const itemCount = useMemo(() => {
    return prepCartItemDetail.cartItems.reduce(
      (total, item) => total + item.quantity,
      0
    );
  }, [prepCartItemDetail.cartItems]);

  const signOutHandler = useCallback(() => {
    setUser({ userName: "", access: false, firstName: " ", lastName: " " });
    resetPrepCartItemDetail();
    goToDashboard();
  }, [goToDashboard, resetPrepCartItemDetail, setUser]);

  const signInHandler = () => {
    goToLogin();
  };

  const handleNavItemClick = useCallback(
    (navItem: NavBarItem) => {
      if (navItem.title === t("nav.more.logOut")) {
        signOutHandler();
      } else {
        navigateTo(navItem.route ?? "Home");
      }
    },
    [signOutHandler, navigateTo, t]
  );

  return {
    handleNavigation,
    isAuthenticated,
    signOutHandler,
    signInHandler,
    itemCount,
    seatName,
    handleNavItemClick,
  };
};
