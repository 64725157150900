import React, { useState, useRef } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { NavBarItem } from "components/Navbar/nav";

interface DropdownItemProps {
  item: NavBarItem;
  onClickAction?: (item: NavBarItem) => void;
}

const DropdownItem: React.FC<DropdownItemProps> = ({
  item,
  onClickAction = () => {},
}) => {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const submenuRef = useRef<HTMLDivElement>(null);

  if (item.isDisabled) {
    return null;
  }

  const handleItemClick = () => {
    if (item.hasDropdown) {
      setIsSubmenuOpen((prev) => !prev);
    } else {
      onClickAction(item);
    }
  };

  return (
    <div className="relative" ref={submenuRef}>
      <button
        onClick={handleItemClick}
        className="flex items-center w-full px-4 py-2 text-left text-black hover:text-mocha focus:outline-none"
      >
        {item.iconType && <item.iconType className="mr-2 text-xl" />}
        <span>{item.title}</span>
        {item.hasDropdown && (
          <span className="ml-auto">
            {isSubmenuOpen ? <FaChevronUp /> : <FaChevronDown />}
          </span>
        )}
      </button>
      {isSubmenuOpen && item.dropdownItems && (
        <div className="mt-1">
          {item.dropdownItems.map((subItem, index) => (
            <DropdownItem
              key={index}
              item={subItem}
              onClickAction={onClickAction}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownItem;
