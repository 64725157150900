import axios, { AxiosInstance } from "axios";
import config from "../config";

// Create an Axios instance with the base URL from config
const apiClient: AxiosInstance = axios.create({
  baseURL: config.apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor to add auth tokens or other headers
apiClient.interceptors.request.use(
  (config) => {
    // Example: Add authentication token
    const token = localStorage.getItem("authToken"); // Adjust based on your auth strategy
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle global response transformations or logging
apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiClient;
