import apiClient from "./apiClient";
import handleApiError from "./errorHandler";
import { ApiResponse, SuccessResponse } from "./types/ApiResponse";
import { AxiosError } from "axios";

/**
 * Creates a success ApiResponse.
 *
 * @param data - The data returned from the API.
 * @param statusCode - The HTTP status code.
 * @param message - Optional message.
 * @returns A SuccessResponse indicating success.
 */
const createSuccessResponse = <T>(
  data: T,
  statusCode: number,
  message: string = "",
): SuccessResponse<T> => ({
  status: "success",
  data,
  statusCode,
  message,
});

/**
 * Simplifies API error handling by centralizing error transformation.
 *
 * @param error - The AxiosError thrown during the API request.
 * @returns A standardized ApiResponse indicating the error state.
 */
const createErrorResponse = <T>(error: AxiosError): ApiResponse<T> => {
  return handleApiError(error);
};

/**
 * Generic GET request.
 *
 * @param url - The API endpoint.
 * @param params - Optional query parameters.
 * @returns A standardized ApiResponse with the fetched data or error information.
 */
export const get = async <T>(
  url: string,
  params?: any,
): Promise<ApiResponse<T>> => {
  try {
    const response = await apiClient.get<T>(url, { params });
    return createSuccessResponse(
      response.data,
      response.status,
      response.statusText,
    );
  } catch (error) {
    return createErrorResponse<T>(error as AxiosError);
  }
};

/**
 * Generic POST request.
 *
 * @param url - The API endpoint.
 * @param data - The data to be sent in the request body.
 * @returns A standardized ApiResponse with the created data or error information.
 */
export const post = async <T>(
  url: string,
  data?: any,
): Promise<ApiResponse<T>> => {
  try {
    const response = await apiClient.post<T>(url, data);
    return createSuccessResponse(
      response.data,
      response.status,
      response.statusText,
    );
  } catch (error) {
    return createErrorResponse<T>(error as AxiosError);
  }
};

/**
 * Generic PUT request.
 *
 * @param url - The API endpoint.
 * @param data - The data to be sent in the request body.
 * @returns A standardized ApiResponse with the updated data or error information.
 */
export const put = async <T>(
  url: string,
  data?: any,
): Promise<ApiResponse<T>> => {
  try {
    const response = await apiClient.put<T>(url, data);
    return createSuccessResponse(
      response.data,
      response.status,
      response.statusText,
    );
  } catch (error) {
    return createErrorResponse<T>(error as AxiosError);
  }
};

/**
 * Generic DELETE request.
 *
 * @param url - The API endpoint.
 * @param params - Optional query parameters.
 * @returns A standardized ApiResponse indicating the result of the delete operation or error information.
 */
export const del = async <T>(
  url: string,
  params?: any,
): Promise<ApiResponse<T>> => {
  try {
    const response = await apiClient.delete<T>(url, { params });
    return createSuccessResponse(
      response.data,
      response.status,
      response.statusText,
    );
  } catch (error) {
    return createErrorResponse<T>(error as AxiosError);
  }
};
