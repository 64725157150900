import React from "react";
import CartBox from "./CartBox";
import { useCart } from "hooks/useCart";
import { StateType } from "enum/StateType";
import { useNavigate } from "react-router-dom";
import { Button } from "components/Button/Button";

interface CartDropdownProps {
  setCartDropdown: (value: boolean) => void;
}

const CartDropdown: React.FC<CartDropdownProps> = ({ setCartDropdown }) => {
  const { prepCartItemDetail, updateItemStateByItemId } = useCart();
  const navigate = useNavigate();

  const handleViewCartClick = () => {
    setCartDropdown(false);
    navigate("/cart"); // Adjust the route if necessary
  };

  return (
    <div className="absolute right-0 top-full bg-white p-0 shadow-lg w-72 z-50 overflow-hidden animate-dropdown">
      <CartBox
        cartItems={prepCartItemDetail.cartItems}
        handleQtyUpdateAction={() => {}}
        totalAmount={prepCartItemDetail.subTotal}
        onPlaceOrderClick={() => {}}
        seatname={prepCartItemDetail.selectedTable || ""}
        updateItemStateByItemId={updateItemStateByItemId}
        showMessageModel={false}
        handleOnCloseModelAction={() => {}}
        orderId={prepCartItemDetail.orderId}
        isCartDropDown={true}
        margin="mt-0"
        createOrderState={StateType.Idle}
        createOrderSuccessHandler={() => {}}
      />
      <div className="text-center m-2">
        <Button
          type="button"
          onClick={handleViewCartClick}
          size="lg"
          text="VIEW CART"
        />
      </div>
    </div>
  );
};

export default CartDropdown;
