import { CartProvider } from "context/CartContext";
import { FoodMenuProvider } from "context/FoodMenuContext";
import { SeatProvider } from "context/SeatContext";
import { UserProvider } from "context/UserContext";
import { I18nProvider } from "i18n/I18nProvider";
import React from "react";
import { Outlet } from "react-router-dom";

const ProvidersLayout: React.FC = () => {
  return (
    <I18nProvider defaultLanguage="en">
      <UserProvider>
        <FoodMenuProvider>
          <SeatProvider>
            <CartProvider>
              {/* The child route is <RootLayout /> */}
              <Outlet />
            </CartProvider>
          </SeatProvider>
        </FoodMenuProvider>
      </UserProvider>
    </I18nProvider>
  );
};

export default ProvidersLayout;
