import React, { useState, useRef } from "react";
import { NavBarItem } from "./nav";
import { useClickOutside } from "hooks/util/useClickOutside";
import CartDropdown from "components/Cart/CartDropdown";

interface CartNavItemProps {
  item: NavBarItem;
  cartItemsCount?: number;
  onClickAction?: (item: NavBarItem) => void;
}

const CartNavItem: React.FC<CartNavItemProps> = ({
  item = { title: "Default Title", iconType: undefined, route: undefined },
  cartItemsCount = 0,
  onClickAction = () => {},
}) => {
  const [isCartDropdownOpen, setIsCartDropdownOpen] = useState(false);
  const navItemRef = useRef<HTMLDivElement>(null);

  useClickOutside(navItemRef, () => {
    setIsCartDropdownOpen(false);
  });

  const handleMouseEnter = () => {
    setIsCartDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setIsCartDropdownOpen(false);
  };

  const handleClick = () => {
    if (item.route) {
      onClickAction(item);
    }
  };

  return (
    <div
      className="relative"
      ref={navItemRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <button
        onClick={handleClick}
        className="m-1 font-bold text-lg font-arsenal flex items-center px-4 py-2 text-black hover:text-mocha focus:outline-none"
      >
        {item?.iconType ? (
          <item.iconType className="mr-2 text-xl" />
        ) : (
          <div></div>
        )}{" "}
        {item.title && <span>{item.title}</span>}
        {cartItemsCount > 0 && (
          <span className="ml-2 text-sm bg-red-500 text-white rounded-full px-2">
            {cartItemsCount}
          </span>
        )}
      </button>
      {isCartDropdownOpen && (
        <CartDropdown setCartDropdown={setIsCartDropdownOpen} />
      )}
    </div>
  );
};

export default CartNavItem;
