import classNames from "classnames";
import React from "react";

interface EmptyCartMessageBoxProps {
  message?: string;
  width?: string;
  height?: string;
}

const EmptyCartMessageBox: React.FC<EmptyCartMessageBoxProps> = ({
  message = "No items added to the cart.",
  width = "w-16",
  height = "h-16",
}) => {
  return (
    <div className="flex flex-col items-center justify-center py-10">
      <svg
        className={classNames("text-gray-400 mb-4", width, height)}
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13l-1.5 7h13l-1.5-7M7 13H5m6 0a2 2 0 100 4 2 2 0 000-4zm6 0a2 2 0 100 4 2 2 0 000-4z"
        />
      </svg>
      <p className="text-gray-500 text-lg">{message}</p>
    </div>
  );
};

export default EmptyCartMessageBox;
