export type Language = "en" | "np";

export const translations: Record<Language, Record<string, any>> = {
  en: {
    title: "Laxmi Mithai",
    userName: "Username",
    greeting: "Hello",
    login: "LOGIN",
    welcome: "Welcome to our site!",
    buttonText: "Click Me",
    categories: {
      BREAKFAST: "Breakfast",
      DRINKS: "Drinks",
      FLOUR_MITHAI: "Flour Mithai",
      MILK_MITHAI: "Milk Mithai",
      SNACKS: "Snacks",
    },

    // Left Section
    "nav.laxmiMithai": "Laxmi Mithai",
    "nav.laxmiMithaiLogo": "Laxmi Mithai Logo",

    // Central Section
    "nav.home": "Home",
    "nav.menu": "Menu",
    "nav.seat": "Seat",
    "nav.orderDetails": "Order Details",

    // Right Section
    "nav.cart": "Cart",
    "nav.signIn": "Sign In",
    "nav.more": "More",

    // Dropdown Items under "More"
    "nav.more.menu": "Menu",
    "nav.more.dashboard": "Dashboard",
    "nav.more.qrMenu": "QrMenu",
    "nav.more.admin": "Admin",
    "nav.more.admin.userManagement": "User Management",
    "nav.more.admin.menuManager": "Menu Manager",
    "nav.more.expenses": "Expenses",
    "nav.more.expenses.reports": "Reports",
    "nav.more.expenses.analytics": "Analytics",
    "nav.more.logOut": "Log Out",
  },
  np: {
    title: "लक्ष्मी मिठाई",
    userName: "प्रयोगकर्ता नाम",
    greeting: "नमस्ते",
    login: "लगइन गर्नुहोस्",
    welcome: "हाम्रो साइटमा स्वागत छ!",
    buttonText: "थिच्नुहोस्",
    categories: {
      BREAKFAST: "ब्रेकफास्ट",
      DRINKS: "पेय पदार्थहरू",
      FLOUR_MITHAI: "आटाको मिठाई",
      MILK_MITHAI: "दूधको मिठाई",
      SNACKS: "स्न्याक्स",
    },

    // Left Section
    "nav.laxmiMithai": "लक्ष्मी मिठाई",
    "nav.laxmiMithaiLogo": "लक्ष्मी मिठाई लोगो",

    // Central Section
    "nav.home": "गृहपृष्ठ",
    "nav.menu": "मेनु",
    "nav.seat": "सीट",
    "nav.orderDetails": "अर्डर विवरण",

    // Right Section
    "nav.cart": "कार्ट",
    "nav.signIn": "साइन इन",
    "nav.more": "थप",

    // Dropdown Items under "More"
    "nav.more.menu": "मेनु",
    "nav.more.dashboard": "ड्यासबोर्ड",
    "nav.more.qrMenu": "क्यूआर मेनु",
    "nav.more.admin": "प्रशासक",
    "nav.more.admin.userManagement": "प्रयोगकर्ता व्यवस्थापन",
    "nav.more.admin.menuManager": "मेनु व्यवस्थापक",
    "nav.more.expenses": "व्यय",
    "nav.more.expenses.reports": "रिपोर्टहरू",
    "nav.more.expenses.analytics": "विश्लेषण",
    "nav.more.logOut": "लग आउट",
  },
};
