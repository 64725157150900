import React, { useState, useRef } from "react";
import { NavBarItem } from "./nav";
import { useClickOutside } from "hooks/util/useClickOutside";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import DropdownItem from "components/common/DropDownItem";

interface NavDropdownProps {
  item: NavBarItem;
  onClickAction?: (item: NavBarItem) => void;
  width?: string;
  isMobile?: boolean;
}

const NavDropdown: React.FC<NavDropdownProps> = ({
  item,
  onClickAction = () => {},
  width = "w-56",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useClickOutside(dropdownRef, () => {
    setIsOpen(false);
  });

  if (item.isDisabled) {
    return null;
  }

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className="relative group" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className="flex items-center px-3 py-2 focus:outline-none text-black hover:text-mocha"
        aria-haspopup="true"
        aria-expanded={isOpen}
      >
        {item.iconType && <item.iconType className="text-xl mr-1" />}
        {item.showTextWithIcon && (
          <span className="font-bold">{item.title}</span>
        )}
        <span className="ml-1">
          {isOpen ? <FaChevronUp /> : <FaChevronDown />}
        </span>
      </button>
      {isOpen && (
        <div
          className={`absolute right-0 mt-2 ${width} bg-primary-bg shadow-lg rounded-md z-50 animate-slide-in-right`}
        >
          {item.dropdownItems?.map((dropdownItem, index) => (
            <DropdownItem
              key={index}
              item={dropdownItem}
              onClickAction={onClickAction}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default NavDropdown;
