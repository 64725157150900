import React, { useState } from "react";
import { Food } from "service/foodService";

interface ListFoodProps {
  filteredFoods: Food[];
  selectedCategory: string | null;
}

const ListFood: React.FC<ListFoodProps> = ({
  filteredFoods,
  selectedCategory,
}) => {
  // State to toggle between showing items with and without images
  const [showWithImage, setShowWithImage] = useState(true); // Default to showing items with images

  return (
    /* Food Names and Prices Section based on selected category */
    <div className="my-10">
      {/* Flex container to position heading and toggle button */}
      <div className="flex items-center justify-between mb-4">
        <h3 className="font-arsenal font-semibold mb-4 text-center text-mocha text-2xl">
          {selectedCategory}
        </h3>

        {/* Toggle Switch */}
        <label className="relative inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            checked={showWithImage}
            onChange={() => setShowWithImage((prev) => !prev)}
            className="sr-only peer"
          />
          <div className="w-8 h-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-primary-font-color rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-4 peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[1px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-transform after:duration-200 dark:border-gray-600 peer-checked:bg-primary-font-color"></div>
        </label>
      </div>

      <div className="space-y-2">
        {filteredFoods.length > 0 ? (
          filteredFoods.map((food, index) => (
            <div
              key={index}
              className="flex items-center p-2 bg-gray-20 rounded"
            >
              {showWithImage && food.img && (
                <img
                  src={food.img ?? undefined}
                  alt={food.name}
                  className="w-16 h-16 object-cover rounded mr-4"
                />
              )}
              <div className="flex-1">
                <span className="font-arsenal">{food.name}</span>{" "}
                {/* Food name */}
              </div>
              <span className="text-bold font-medium font-arsenal text-primary-text-color">
                रु {food.price}
              </span>
            </div>
          ))
        ) : (
          <div className="text-center text-gray-500">
            No items available in this category
          </div>
        )}
      </div>
    </div>
  );
};

export default ListFood;
