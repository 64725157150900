import React, { useMemo } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import { OrderItem } from "service/orderService";

interface SecondaryCartItemProps {
  item: OrderItem;
  onIncrement: (count: number, itemName: string, itemId: number) => void;
  onDecrement: (count: number, itemName: string, itemId: number) => void;
}

const SecondaryCartItem: React.FC<SecondaryCartItemProps> = ({
  item,
  onIncrement,
  onDecrement,
}) => {
  //const unitInKg = item.unitInKg ?? 0;

  const itemTotal = useMemo(
    () => item.price * item.quantity,
    [item.quantity, item.price]
  );

  return (
    <tr className="text-sm align-middle">
      <td className="py-3">
        <div className="flex items-start space-x-3">
          {item.imageUrl && (
            <div className="h-16 w-16 flex-shrink-0">
              <img
                src={item.imageUrl}
                alt={item.productName}
                className="h-full w-full object-cover"
              />
            </div>
          )}
          <div>
            <p className="font-medium">{item.productName}</p>
            <p className="text-gray-500">{item.productName}</p>{" "}
            {/* Assuming description exists */}
          </div>
        </div>
      </td>

      <td className="py-3">
        <div className="flex items-center space-x-2">
          <button
            className="p-1 bg-white-200 text-black rounded hover:bg-gray-300"
            onClick={() =>
              onDecrement(item.quantity, item.productName, item.orderItemId)
            }
            aria-label={`Decrease quantity of ${item.productName}`}
          >
            <FaMinus />
          </button>
          {/* Quantity Box */}
          <div className="w-8 h-6 border border-black-600 flex items-center justify-center">
            <span className="text-gray-800">{item.quantity}</span>
          </div>
          <button
            className="p-1 bg-white-200 text-black rounded hover:bg-gray-300"
            onClick={() =>
              onIncrement(item.quantity, item.productName, item.orderItemId)
            }
            aria-label={`Increase quantity of ${item.productName}`}
          >
            <FaPlus />
          </button>
        </div>
      </td>

      <td className="py-3">
        <span>रु {item.price.toFixed(2)}</span>
      </td>

      <td className="py-3 font-medium">रु {itemTotal}</td>
    </tr>
  );
};

export default SecondaryCartItem;
