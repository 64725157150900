// src/components/ProtectedRoute/ProtectedRoute.tsx
import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { PATHS } from "constants/router/routes";
import { useUserContext } from "context/UserContext";

interface ProtectedRouteProps {
  /**
   * If you want this protected route to require user.access === true.
   * You could also add more conditions, like roles, if you expand the system.
   */
  requiredAccess?: boolean;
  redirectPath?: string;
  children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  requiredAccess = true,
  redirectPath = PATHS.HOME,
  children,
}) => {
  const { user } = useUserContext();

  // Check for your condition; in this example, if user.access must be true
  if (user.access !== requiredAccess) {
    // If condition is not met, navigate away
    return <Navigate to={redirectPath} replace />;
  }

  // If user has required access, render the protected children
  return <>{children}</>;
};

export default ProtectedRoute;
