import React from "react";
import { Category } from "service/foodService";

interface CategoryBarProps {
  categories: Category[];
  selectedCategory: string | null;
  onClickSelectedCategory: (category: string) => void;
}

const CategoryBar: React.FC<CategoryBarProps> = ({
  categories,
  selectedCategory,
  onClickSelectedCategory,
}) => {
  return (
    <div className="flex space-x-4 overflow-x-auto p-2 mb-4 border-b border-gray-200 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-rounded-full hover:scrollbar-thumb-gray-400">
      {categories.map((category, index) => (
        <span
          key={index}
          className={`px-4 py-2 whitespace-nowrap cursor-pointer border-b-2 ${
            selectedCategory === category.name
              ? "text-mocha bg-primary-bg border-transparent"
              : "text-black border-transparent hover:border-light-brown active:border-primary-font-color"
          }`}
          onClick={() => onClickSelectedCategory(category.name)}
        >
          {category.name}
        </span>
      ))}
    </div>
  );
};

export default CategoryBar;
