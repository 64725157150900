import { useCartContext } from "context/CartContext";
import { useCallback } from "react";

export const useCart = () => {
  const {
    addToCartAction,
    prepCartItemDetail,
    setPrepCartItemDetail,
    qtyUpdateActionHandler,
    GetOrdersByTableAndStatus,
    existingOrdersStateBySeat,
    updateItemStateByItemId,
    resetExistingOrdersState,
  } = useCartContext();

  const calAmoutAfterDiscount = useCallback(
    (discountAmount: number) => {
      setPrepCartItemDetail((prev) => {
        const updatedTotal = prev.subTotal - discountAmount;
        //update totalAmount and discountAmount in existing prepCartItems
        return {
          ...prev,
          totalAmount: updatedTotal,
          discountAmount: discountAmount,
        };
      });
    },
    [setPrepCartItemDetail]
  );

  return {
    GetOrdersByTableAndStatus,
    addToCartAction,
    qtyUpdateActionHandler,
    calAmoutAfterDiscount,
    prepCartItemDetail,
    existingOrdersStateBySeat,
    updateItemStateByItemId,
    resetExistingOrdersState,
  };
};
