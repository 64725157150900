import { useState, useEffect, useRef, useCallback } from "react";
import { useFoodMenuContext } from "../context/FoodMenuContext";
import { StateType } from "enum/StateType";
import { useApiState } from "./State/useApiState";
import {
  addFoodApi,
  Food,
  SuccessMessageResponse,
  updateFoodApi,
} from "service/foodService";
import { Result } from "service/types/Result";

export const useFoodMenu = () => {
  const {
    foods,
    categories,
    loadFoods,
    loadCategories,
    foodState,
    categoryState,
  } = useFoodMenuContext();

  // State Management
  const [searchTerm, setSearchTerm] = useState<string>("");

  // Ref to track if fetchFood has been called
  const hasFetchedRef = useRef(false);

  const createFoodApiState = useApiState();
  const updateFoodApiState = useApiState();

  useEffect(() => {
    if (
      !hasFetchedRef.current &&
      foods.length === 0 &&
      foodState.state !== StateType.Loading
    ) {
      loadFoods("food", "ALL");
      hasFetchedRef.current = true;
    }
  }, [loadFoods, foods.length, foodState]);

  // Handle Search Input Change
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value;
    setSearchTerm(searchValue);
  };

  // Handle Search on Enter Key
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && searchTerm.trim() !== "") {
      onSearchFoodClick(searchTerm.trim(), "NAME");
    }
  };

  // Handle Category Click
  const handleCategoryClick = (category: string) => {
    const action = category === "ALL" ? "ALL" : "category";
    onSearchFoodClick(category, action);
  };

  // Search Food
  const onSearchFoodClick = (searchValue: string, action: string) => {
    loadFoods(searchValue, action);
  };

  const createFood = useCallback(
    async (newFood: Food) => {
      createFoodApiState.loading();
      const result: Result<SuccessMessageResponse, string> =
        await addFoodApi(newFood);

      switch (result.type) {
        case "success":
          createFoodApiState.success();
          loadFoods("food", "ALL");
          break;
        case "failure":
          createFoodApiState.failure(result.error);
          break;
        default:
          createFoodApiState.failure("An unexpected error occurred.");
      }
    },
    [createFoodApiState, loadFoods]
  );

  const updateFood = useCallback(
    async (newFood: Food) => {
      updateFoodApiState.loading();
      const result: Result<SuccessMessageResponse, string> =
        await updateFoodApi(newFood);

      switch (result.type) {
        case "success":
          updateFoodApiState.success();
          loadFoods("food", "ALL");
          break;
        case "failure":
          updateFoodApiState.failure(result.error);
          break;
        default:
          updateFoodApiState.failure("An unexpected error occurred.");
      }
    },
    [updateFoodApiState, loadFoods]
  );

  return {
    foods,
    categories,
    searchTerm,
    handleSearchChange,
    handleKeyPress,
    handleCategoryClick,
    foodState,
    categoryState,
    loadFoods,
    loadCategories,
    updateFoodApiState,
    createFoodApiState,
    updateFood,
    createFood,
  };
};
