import { LoadingButton } from "components/Button/LoadingButton";
import { StateType } from "enum/StateType";
import React from "react";

interface OrderSummaryProps {
  cartItemsCount: number;
  subtotalAmount: number;
  totalAmount: number;
  paymentType: string;
  setPaymentType: (type: string) => void;
  discount: number;
  setDiscount: (discount: number) => void;
  onCompleteOrderClick: () => void;
  completeOrderBtnState: StateType;
  completeOrderApiError: string | null;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({
  cartItemsCount,
  subtotalAmount,
  totalAmount,
  paymentType,
  setPaymentType,
  discount,
  setDiscount,
  onCompleteOrderClick,
  completeOrderBtnState,
  completeOrderApiError,
}) => {
  const handleDiscountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);
    setDiscount(isNaN(value) ? 0 : value);
  };

  return (
    <div>
      <div className="px-6 py-5">
        <h2 className="text-lg font-semibold">Order Summary</h2>
      </div>

      <hr className="my-3 w-3/4 border-black-800 mx-auto" />

      <div className="flex flex-1 flex-col justify-between px-6 space-y-6">
        {/* Payment & Discount */}
        <div className="space-y-4">
          {/* Items Count */}
          <div className="flex items-center justify-between">
            <span className="text-sm py-3">ITEMS {cartItemsCount}</span>
            <span className="font-medium">रु {subtotalAmount.toFixed(2)}</span>
          </div>

          {/* Payment Type */}
          <div>
            <label htmlFor="paymentType" className="block text-sm">
              Payment Type
            </label>
            <select
              id="paymentType"
              className="mt-1 w-full rounded border px-2 py-3 text-sm"
              value={paymentType}
              onChange={(e) => setPaymentType(e.target.value)}
            >
              <option value="">-- Select Payment Type --</option>
              <option value="Cash">Cash</option>
              <option value="Phone Pay">Fone Pay</option>
              <option value="eSewa">Esewa</option>
              <option value="Credit">Credit</option>
            </select>
          </div>

          {/* Discount */}
          <div>
            <label htmlFor="discount" className="block text-sm">
              Discount
            </label>
            <div className="mt-1 space-y-3">
              <input
                type="number" // Changed to number for better UX
                id="discount"
                value={discount}
                onChange={handleDiscountChange}
                placeholder="Enter discount"
                className="w-full rounded border px-2 py-3 text-sm"
              />
            </div>
          </div>

          {/* Display Selected Payment Type */}
          {paymentType && (
            <div className="flex items-center justify-between">
              <span className="text-sm py-1">Payment Type</span>
              <span className="font-medium capitalize">{paymentType}</span>
            </div>
          )}

          {/* Display Applied Discount */}
          {discount > 0 && (
            <>
              <hr className="w-3/4 border-black-800 mx-auto" />
              <div className="flex items-center justify-between">
                <span className="text-sm py-1">Discount Applied</span>
                <span className="font-medium">रु {discount.toFixed(2)}</span>
              </div>
            </>
          )}

          {/* Subtotal */}
          <hr className="w-3/4 border-black-800 mx-auto" />
          <div className="flex items-center justify-between mb-3">
            <span className="text-sm font-semibold">SUB TOTAL</span>
            <span className="text-md font-semibold">
              रु {subtotalAmount.toFixed(2)}
            </span>
          </div>

          {/* Total */}
          <div className="border-t mt-2 py-6">
            <div className="flex items-center justify-between mb-3">
              <span className="text-sm font-semibold">TOTAL</span>
              <span className="text-lg font-bold">
                रु {totalAmount.toFixed(2)}
              </span>
            </div>
            <LoadingButton
              text="COMPLETE ORDER"
              buttonState={completeOrderBtnState}
              onClick={onCompleteOrderClick}
              error={completeOrderApiError}
              size="full"
              variant="primary"
              disabled={paymentType.length <= 0}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
