import React from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
import { OrderItem } from "service/orderService";
import SpinnerLoading from "components/Spinner/SpinnerLoading";
import { StateType } from "enum/StateType";

interface CartItemProps {
  item: OrderItem;
  onIncrement: (quantity: number, itemName: string, itemId: number) => void;
  onDecrement: (quantity: number, itemName: string, itemId: number) => void;
  cartItemState: StateType;
  isCartDropDown?: boolean;
  margin?: string;
  padding?: string;
}

const CartItemComponent: React.FC<CartItemProps> = ({
  item,
  onIncrement,
  onDecrement,
  cartItemState,
  isCartDropDown = false,
  margin = "",
  padding = "",
}) => {
  const unitInKg = item.unitInKg ?? 0;

  return (
    <tr className={`${margin} ${padding}`}>
      <td className="py-2 px-4 text-sm text-gray-700">{item.productName}</td>
      {!isCartDropDown && (
        <td className="py-2 px-4 text-sm text-gray-700">
          {item.unitInKg !== 0 && item.quantity <= 0
            ? `Rs ${item.priceInKg?.toFixed(2)}`
            : `Rs ${item.price.toFixed(2)}`}
        </td>
      )}
      <td className="py-2 px-4 text-sm text-gray-700">
        {isCartDropDown ? (
          <span className="text-gray-800">x {item.quantity}</span>
        ) : item.unitInKg !== 0 && item.quantity <= 0 ? (
          <div className="flex items-center space-x-2">
            <button
              className="p-1 bg-red-500 text-white rounded hover:bg-red-600"
              onClick={() =>
                onDecrement(1, item.productName, item.unitInKg || 0)
              }
              aria-label={`Decrease quantity of ${item.productName}`}
            >
              <FaMinus />
            </button>
            <span className="text-gray-800">kg {unitInKg.toFixed(2)}</span>
          </div>
        ) : (
          <div className="flex items-center space-x-2">
            {cartItemState === StateType.Loading ? (
              <SpinnerLoading size="small" iconName="updating" />
            ) : (
              <>
                <button
                  className="p-1 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
                  onClick={() =>
                    onDecrement(
                      item.quantity,
                      item.productName,
                      item.orderItemId
                    )
                  }
                  aria-label={`Decrease quantity of ${item.productName}`}
                >
                  <FaMinus />
                </button>
                <span className="text-gray-800">{item.quantity}</span>
                <button
                  className="p-1 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
                  onClick={() =>
                    onIncrement(
                      item.quantity,
                      item.productName,
                      item.orderItemId
                    )
                  }
                  aria-label={`Increase quantity of ${item.productName}`}
                >
                  <FaPlus />
                </button>
              </>
            )}
          </div>
        )}
      </td>
      {!isCartDropDown && (
        <td className="py-2 px-4 text-sm text-gray-700">
          {item.unitInKg !== 0 && item.quantity <= 0
            ? `Rs ${(unitInKg * (item.priceInKg || 0)).toFixed(2)}`
            : `Rs ${(item.price * item.quantity).toFixed(2)}`}
        </td>
      )}
    </tr>
  );
};

export default CartItemComponent;
