import React, { useState } from "react";
import FoodLoadingSpinner from "components/Spinner/FoodLoadingSpinner";
import CategoryBar from "components/FoodMenu/CategoryBar";
import { useFoodMenu } from "hooks/useFoodMenu";
import { StateType } from "enum/StateType";
import FoodList from "components/FoodMenu/FoodList";

const QRMenu: React.FC = () => {
  const { foods, foodState, categories } = useFoodMenu();

  // State to manage the currently selected category
  const [selectedCategory, setSelectedCategory] = useState(categories[0].name); // Default to first category

  // Filter foods based on the selected category
  const filteredFoods = foods.filter(
    (food) => food.category_name === selectedCategory
  );

  // Filter further based on the toggle: items with images or without images
  // const displayedFoods = filteredFoods.filter((food) =>
  //   showWithImage ? food.img : !food.img
  // );

  if (foodState.state === StateType.Loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <FoodLoadingSpinner />
      </div>
    );
  }

  // TODO: revise the state logic
  if (foodState.state === StateType.Failure) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        {/* <Alert variant="danger">{foodState.error}</Alert> */}
      </div>
    );
  }

  return (
    <div className="w-full bg-background-color min-h-screen mx-auto py-20 flex justify-center font-roboto">
      <div className="w-[500px] bg-white rounded-md py-6 shadow-xl shadow-gray-600 px-6 h-auto">
        <h2 className="text-center font-bold pb-6 border-b-[1px] text-mocha text-4xl mb-10 font-arsenal">
          Laxmi Mithai
          <span className="block text-xl font-mono">Menu</span>
        </h2>

        {/* Horizontal Scrollable Category Bar */}
        <CategoryBar
          categories={categories}
          selectedCategory={selectedCategory}
          onClickSelectedCategory={setSelectedCategory}
        />

        <FoodList
          filteredFoods={filteredFoods}
          selectedCategory={selectedCategory}
        />
      </div>
    </div>
  );
};

export default QRMenu;
