import { useCallback } from "react";
import { useApiState } from "./State/useApiState";
import {
  createOrderApi,
  completeOrderApi,
  CreateOrderResponse,
  SucessResponse,
  PrepCartItemDetail,
} from "../service/orderService";
import { Result } from "../service/types/Result";
import { PaymentInfo } from "../service/orderService";
import { useCartContext } from "context/CartContext";
import { useNavigation } from "./navigation/navigation";
import { isValidNumber } from "util/DataHelpers";
import { useUserContext } from "context/UserContext";

export function useOrder() {
  const createOrderState = useApiState();
  const completeOrderState = useApiState();
  const { goToOrderDetails } = useNavigation();

  const {
    GetOrdersByTableAndStatus,
    existingOrdersStateBySeat,
    resetPrepCartItemDetail,
    prepCartItemDetail,
  } = useCartContext();

  const { user } = useUserContext();

  const createOrderApiCall = useCallback(
    async (cartItemDetails: PrepCartItemDetail) => {
      createOrderState.loading();
      const result: Result<CreateOrderResponse, string> = await createOrderApi(
        cartItemDetails,
        user.id
      );
      switch (result.type) {
        case "success":
          createOrderState.success();
          break;
        case "failure":
          createOrderState.failure(result.error);
          break;
        default:
          createOrderState.failure("An unexpected error occurred.");
      }
    },
    [createOrderState, user.id]
  );

  const completeOrderApiCall = useCallback(
    async (paymentInfo: PaymentInfo, orderId: number) => {
      completeOrderState.loading();
      const result: Result<SucessResponse, string> = await completeOrderApi(
        paymentInfo,
        orderId
      );

      switch (result.type) {
        case "success":
          completeOrderState.success();
          goToOrderDetails();
          resetPrepCartItemDetail();
          break;
        case "failure":
          completeOrderState.failure(result.error);
          break;
        default:
          completeOrderState.failure("An unexpected error occurred.");
      }
    },
    [completeOrderState, goToOrderDetails, resetPrepCartItemDetail]
  );

  const compleOrderHandler = (paymentType: string) => {
    const { orderId, totalAmount, subTotal, discountAmount } =
      prepCartItemDetail;

    if (!isValidNumber(orderId)) {
      return;
    }
    const paymentInfo = {
      paymentType,
      debitAmount: totalAmount,
      creditAmount: 0,
      discountAmount: discountAmount,
      totalAmount: totalAmount,
      subTotal: subTotal,
      paymentStatus: paymentType,
    };
    completeOrderApiCall(paymentInfo, orderId);
  };

  const handleAddOrder = useCallback(
    (seatName: string) => {
      GetOrdersByTableAndStatus(seatName, "menu");
    },
    [GetOrdersByTableAndStatus]
  );

  const handleCompleteOrder = useCallback(
    (seatName: string) => {
      GetOrdersByTableAndStatus(seatName, "cart");
    },
    [GetOrdersByTableAndStatus]
  );

  return {
    createOrderState: createOrderState.state,
    completeOrderState: completeOrderState.state,
    completeOrderApiError: completeOrderState.error,
    createOrderApiCall,
    completeOrderApiCall,
    handleAddOrder,
    handleCompleteOrder,
    existingOrdersStateBySeat,
    compleOrderHandler,
  };
}
