import { ApiResponse } from "../api/types/ApiResponse";
import { Result } from "./types/Result";

/**
 * ApiHandler class to process API responses and errors.
 */
export class ApiHandler {
  /**
   * Processes the ApiResponse and returns a Result.
   *
   * @param response - The ApiResponse received from the API call.
   * @returns A Result object representing success or failure.
   */
  static handleApiResponse<T>(response: ApiResponse<T>): Result<T, string> {
    if (response.status === "success" && response.data) {
      return { type: "success", data: response.data as T };
    } else if (response.status === "error") {
      let errorMessage: string;
      if (response.statusCode === 404) {
        errorMessage = response.message || "Not Found";
      } else if (response.statusCode >= 500) {
        errorMessage = response.message || "A server error occurred.";
      } else {
        errorMessage = response.message || "A network error occurred.";
      }
      return { type: "failure", error: errorMessage };
    } else {
      return { type: "failure", error: "An unexpected error occurred." };
    }
  }

  /**
   * Handles unexpected errors (e.g., network issues).
   *
   * @param error - The error object caught during the API call.
   * @returns A Failure Result with a generic error message.
   */
  static handleApiError(error: any): Result<any, string> {
    let errorMessage: string = "Something went wrong.";

    if (error.response) {
      // Server responded with a status other than 2xx
      if (error.response.status === 404) {
        errorMessage = error.response.data?.message || "Not Found";
      } else if (error.response.status >= 500) {
        errorMessage =
          error.response.data?.message || "A server error occurred.";
      } else {
        errorMessage =
          error.response.data?.message || "A network error occurred.";
      }
    } else if (error.request) {
      // No response received from server
      errorMessage = "No response received from the server.";
    } else {
      // Something else caused the error
      errorMessage = error.message || "An unknown error occurred.";
    }

    return { type: "failure", error: errorMessage };
  }
}
