import React from "react";
import { NavLink } from "react-router-dom";
import { NavBarItem } from "./nav";
import classNames from "classnames";

interface NavItemProps {
  item: NavBarItem;
  cartItemsCount?: number;
  onClickAction?: (item: NavBarItem) => void;
}

const NavItem: React.FC<NavItemProps> = ({
  item,
  cartItemsCount = 0,
  onClickAction = () => {},
}) => {
  const handleClick = () => {
    onClickAction(item);
  };

  if (item.isDisabled) {
    return null;
  }

  return (
    <NavLink
      to={item.route!}
      onClick={handleClick}
      className={classNames(
        "flex relative px-3 py-2 group items-center space-x-1",
      )}
    >
      {({ isActive }) => (
        <>
          {/* Render icon or icon with text */}
          <div className="flex items-center">
            {item.image ? (
              <img
                src={item.image.src}
                alt={item.image.alt}
                className={classNames(item.image.className, "h-8 w-8")}
              />
            ) : item.iconType ? (
              <item.iconType
                className={classNames(
                  "text-2xl transition-colors duration-300",
                  {
                    "text-mocha": isActive,
                    "group-hover:text-mocha": true,
                  },
                )}
              />
            ) : null}

            {/* Show text next to icon if applicable */}
            {item.showTextWithIcon && (
              <span
                className={classNames(
                  "ml-1 font-bold text-lg font-arsenal transition-colors duration-300",
                  "truncate", // Truncate overflowing text with ellipsis
                  "max-w-full", // Use full width available for the text
                  {
                    "text-mocha": isActive,
                    "group-hover:text-mocha": true,
                  },
                )}
              >
                {item.title}
              </span>
            )}
          </div>

          {/* Cart Item Count */}
          {cartItemsCount > 0 && (
            <span className="ml-1 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-green-600 rounded-full">
              {cartItemsCount}
            </span>
          )}

          {/* Hover Effect (Underline and Hover Text) */}
          <>
            {/* Hover Effect Text */}
            {item.showHoverText && (
              <span
                className={classNames(
                  "absolute top-full mt-2 left-1/2 transform -translate-x-1/2 px-3 py-1 text-sm text-white bg-black rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-300 text-center whitespace-normal min-w-max",
                  {
                    "text-mocha": isActive,
                  },
                )}
              >
                {item.title}
              </span>
            )}

            {/* Underline Effect */}
            {!item.isUnderLineEffectDisabled && (
              <span
                className={classNames(
                  "absolute left-0 bottom-0 h-0.5 w-full bg-mocha transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left",
                  {
                    "scale-x-100": isActive,
                  },
                )}
              ></span>
            )}
          </>
        </>
      )}
    </NavLink>
  );
};

export default NavItem;
