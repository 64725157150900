import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
  useMemo,
} from "react";
import { ApiState, useApiState } from "../hooks/State/useApiState";
import { Result } from "../service/types/Result";
import {
  Category,
  fetchCategories,
  fetchFoods,
  Food,
  GetAllCategoryResponse,
  GetFoodsResponse,
} from "../service/foodService";

type FoodMenuContextType = {
  loadFoods: (searchValue: string, action: string) => Promise<void>;
  loadCategories: () => Promise<void>;
  categories: Category[];
  foods: Food[];
  foodState: ApiState;
  categoryState: ApiState;
  errorMsg?: string;
};

export const FoodMenuContext = createContext<FoodMenuContextType | undefined>(
  undefined
);

interface FoodMenuProviderProps {
  children: ReactNode;
}

export const FoodMenuProvider: React.FC<FoodMenuProviderProps> = ({
  children,
}) => {
  // Custom hooks
  const foodState = useApiState();
  const categoryState = useApiState();

  // local state
  const [foods, setFoods] = useState<Food[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);

  const loadFoods = useCallback(
    async (searchValue: string, action: string) => {
      foodState.loading();
      const result: Result<GetFoodsResponse, string> = await fetchFoods(
        searchValue,
        action
      );

      switch (result.type) {
        case "success":
          setFoods(result.data.payload);
          foodState.success();

          break;
        case "failure":
          foodState.failure(result.error);
          break;
        default:
          foodState.failure("An unexpected error occurred.");
      }
    },
    [foodState, setFoods]
  );

  const loadCategories = useCallback(async () => {
    categoryState.loading();
    const result: Result<GetAllCategoryResponse, string> =
      await fetchCategories();

    switch (result.type) {
      case "success":
        setCategories(result.data.payload);
        categoryState.success();
        break;
      case "failure":
        categoryState.failure(result.error);
        break;
      default:
        categoryState.failure("An unexpected error occurred.");
    }
  }, [categoryState, setCategories]);

  const value: FoodMenuContextType = useMemo(
    () => ({
      loadFoods,
      loadCategories,
      categories,
      foods,
      foodState: foodState,
      categoryState: categoryState,
    }),
    [loadFoods, loadCategories, categories, foods, foodState, categoryState]
  );

  return (
    <FoodMenuContext.Provider value={value}>
      {children}
    </FoodMenuContext.Provider>
  );
};

export const useFoodMenuContext = () => {
  const context = useContext(FoodMenuContext);
  if (!context) {
    throw new Error(
      "useFoodMenuContext must be used within a FoodMenuProvider"
    );
  }
  return context;
};
