import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css"; // Global CSS
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary"; // Error boundary wrapper
import FoodLoadingSpinner from "components/Spinner/FoodLoadingSpinner"; // Loading spinner
import { router } from "./constants/router/router"; // Router configuration
import { RouterProvider } from "react-router-dom"; // React Router for route management

// Initialize the root element using React's concurrent mode
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// Render the application
root.render(
  <React.StrictMode>
    {/* Error Boundary to catch errors in the child tree */}
    <ErrorBoundary>
      {/* Suspense for lazy-loading fallback */}
      <React.Suspense fallback={<FoodLoadingSpinner />}>
        {/* React Router Provider */}
        <RouterProvider router={router} />
      </React.Suspense>
    </ErrorBoundary>
  </React.StrictMode>
);
