import ProtectedRoute from "components/ProtectedRoute/ProtectedRoute";
import CartPage from "pages/Cart/CartPage";
import QRMenu from "pages/Menu/QRMenu";
import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const Login = lazy(() => import("../../pages/Login/Login"));
const Home = lazy(() => import("../../pages/Home/Home"));
const DashboardPage = lazy(() => import("../../pages/Dashboard/Dashboard"));
const Table = lazy(() => import("../../pages/Table/Table"));
const MenuManager = lazy(() => import("../../pages/Menu/MenuManager"));
const FoodOrderMenu = lazy(() => import("../../pages/Menu/FoodOrderMenu"));
const OrderDetails = lazy(() => import("../../pages/OrderDetail/OrderDetails"));
const NotFound = lazy(() => import("../../pages/NotFound/NotFound"));

export const PATHS = {
  HOME: "/home",
  LOG_IN: "/login",
  DASHBOARD: "/dashboard",
  TABLE: "/seatTable",
  ORDER_MENU: "/orderMenu/:seatName",
  VIEWMENU: "/viewMenu",
  CART: "/cart",
  ORDER_DETAILS: "/orderDetails",
  MENU_MANAGER: "/menuManager",
} as const;

export const ROUTES: RouteObject[] = [
  {
    index: true,
    element: <DashboardPage />,
  },
  {
    path: PATHS.HOME,
    element: <Home />,
  },
  {
    path: PATHS.LOG_IN,
    element: <Login />,
  },
  {
    path: PATHS.DASHBOARD,
    element: <DashboardPage />,
  },
  {
    path: PATHS.TABLE,
    element: <Table />,
  },
  {
    path: PATHS.ORDER_MENU,
    element: <FoodOrderMenu />,
  },
  {
    path: PATHS.VIEWMENU,
    element: <QRMenu />,
  },
  {
    path: PATHS.CART,
    element: <CartPage />,
  },
  {
    path: PATHS.ORDER_DETAILS,
    element: (
      <ProtectedRoute requiredAccess={true} redirectPath={PATHS.HOME}>
        <OrderDetails />
      </ProtectedRoute>
    ),
  },
  {
    path: PATHS.MENU_MANAGER,
    element: <MenuManager />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];
