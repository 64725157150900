import { useI18n } from "i18n/I18nProvider";
import React from "react";
import { FaLanguage } from "react-icons/fa";

const LanguageToggle: React.FC = () => {
  const { language, toggleLanguage } = useI18n();

  return (
    <button
      onClick={toggleLanguage}
      aria-label="Toggle Language"
      className="
        flex 
        items-center 
        border-gray-300 
        text-black-700 
        hover:text-mocha 
        focus:outline-none 
        focus:ring-2 
        focus:ring-black
        rounded 
        px-4 
        py-2 
        transition 
        duration-300
      "
    >
      <FaLanguage className="mr-2 text-xl" />
      {language === "en" ? (
        <span className="text-lg">नेपाली 🇳🇵</span>
      ) : (
        <span className="text-lg">English 🇺🇸</span>
      )}
    </button>
  );
};

export default LanguageToggle;
