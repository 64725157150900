import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { translations, Language } from "./translations";

// Define the shape of the i18n context
interface I18nContextProps {
  language: Language;
  t: (key: string, vars?: Record<string, string | number>) => string;
  switchLanguage: (lang: Language) => void;
  toggleLanguage: () => void;
}

// Create the context with default values
const I18nContext = createContext<I18nContextProps>({
  language: "en",
  t: (key) => key,
  switchLanguage: () => {},
  toggleLanguage: () => {},
});

// Define props for the provider
interface I18nProviderProps {
  defaultLanguage?: Language;
  children: ReactNode;
}

// Create the provider component
export const I18nProvider: React.FC<I18nProviderProps> = ({
  defaultLanguage = "en",
  children,
}) => {
  const [language, setLanguage] = useState<Language>(defaultLanguage);

  // Load saved language preference from localStorage
  useEffect(() => {
    const savedLang = localStorage.getItem("appLanguage") as Language | null;
    if (savedLang && translations[savedLang]) {
      setLanguage(savedLang);
    }
  }, []);

  // Save language preference to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("appLanguage", language);
  }, [language]);

  // Translation function
  const t = (key: string, vars?: Record<string, string | number>): string => {
    const translation = translations[language][key];

    if (!translation) {
      console.warn(
        `Missing translation for key: "${key}" in language: "${language}"`
      );
      return key; // Fallback to key if translation not found
    }

    if (vars) {
      // Simple interpolation: replace {{key}} with vars[key]
      return Object.keys(vars).reduce((acc, varKey) => {
        const regex = new RegExp(`{{${varKey}}}`, "g");
        return acc.replace(regex, String(vars[varKey]));
      }, translation);
    }

    return translation;
  };

  // Function to switch to a specific language
  const switchLanguage = (lang: Language) => {
    if (translations[lang]) {
      setLanguage(lang);
    } else {
      console.warn(`Language "${lang}" is not supported.`);
    }
  };

  // Function to toggle between English and Nepali
  const toggleLanguage = () => {
    setLanguage((prev) => (prev === "en" ? "np" : "en"));
  };

  return (
    <I18nContext.Provider
      value={{ language, t, switchLanguage, toggleLanguage }}
    >
      {children}
    </I18nContext.Provider>
  );
};

// Custom hook to use the i18n context
export const useI18n = () => useContext(I18nContext);
