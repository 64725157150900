import React from "react";
import classNames from "classnames";

/** Possible sizes for the spinner */
export type SpinnerSize = "sm" | "md" | "lg" | "xl";

/** Theming or "flavors" for a food-based app; feel free to add more! */
export type SpinnerVariant =
  | "default"
  | "bread"
  | "sauce"
  | "sizzling"
  | "black";

/**
 * Optional color classes for each variant
 * (using Tailwind as an example; adjust to your styling system).
 */
const variantClasses: Record<SpinnerVariant, string> = {
  default: "border-white border-4 animate-spin-slow", // uses current text color
  bread: "border-yellow-600",
  sauce: "border-red-600",
  sizzling: "border-orange-600",
  black: "border-black border-4 animate-spin-slow",
};

/** Props for the Spinner component */
export interface SpinnerProps {
  /**
   * The spinner size which affects width/height and border thickness
   */
  size?: SpinnerSize;
  /**
   * A variant (theme/flavor) of the spinner
   */
  variant?: SpinnerVariant;
  /**
   * An accessible label for screen readers
   */
  label?: string;
  /**
   * Additional className for advanced styling overrides
   */
  className?: string;
}

/**
 * A reusable, accessible Spinner component.
 * Perfect for indicating loading states in a food-related app or any other scenario.
 */
export const Spinner: React.FC<SpinnerProps> = ({
  size = "md",
  variant = "default",
  label = "Loading…",
  className,
}) => {
  // Predefined size classes controlling dimension & border thickness
  const spinnerSize = {
    sm: "w-4 h-4 border-2", // smaller spinner
    md: "w-6 h-6 border-2", // medium spinner
    lg: "w-8 h-8 border-4", // large spinner
    xl: "w-10 h-10 border-2",
  }[size];

  // Choose appropriate border color classes based on variant
  const spinnerColor = variantClasses[variant];

  return (
    <div
      role="status"
      aria-label={label}
      className={classNames(
        // Common spinner styles:
        // - inline-block ensures the spinner doesn't force a new line
        // - rounded-full for a circle shape
        // - animate-spin for continuous rotation
        // - border-t-transparent to create the "donut" effect
        "inline-block rounded-full animate-spin border-t-transparent",
        spinnerSize,
        spinnerColor,
        className,
      )}
    >
      {/* sr-only text for screen readers */}
      <span className="sr-only">{label}</span>
    </div>
  );
};
