import React from "react";
import classNames from "classnames";
import { ButtonVariant, ColorOverrides } from "./Button.types";
import { IconType } from "react-icons";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Visual variant, e.g. 'primary', 'secondary', 'danger',
   * 'info', or 'success'.
   */
  variant?: ButtonVariant;
  /** Optionally override colors, text, etc. */
  colorOverrides?: ColorOverrides;
  /**
   * Size for the button. We keep the same height for sm, md, and lg,
   * but increase width as the size grows.
   */
  size?: "sm" | "md" | "lg" | "xl" | "full";

  /**
   * The text to display inside the button.
   * If provided, it will be rendered instead of children.
   */
  text?: string;

  /**
   * Text size for the button label.
   * Defaults to 'base'.
   */
  textSize?: "sm" | "base" | "lg" | "xl";

  /**
   * Font weight for the button label.
   * Defaults to 'bold'.
   */
  fontWeight?: "normal" | "medium" | "semibold" | "bold";

  customIcon?: IconType;
}

/**
 * Pre-defined color combos for each variant.
 * Mocha-brown theme is used for 'primary' by default.
 */
const variantClasses: Record<ButtonVariant, string> = {
  // "Mocha brown" style for primary
  primary: "bg-[#8B4513] hover:bg-[#A0522D] text-white focus:ring-[#8B4513]",

  // A subtle cream color scheme for secondary
  secondary:
    "bg-[#F5F5DC] hover:bg-[#EDEAD0] text-[#8B4513] focus:ring-[#8B4513]",

  // Other variants (danger, info, success) placeholders
  danger: "bg-red-600 hover:bg-red-500 text-white focus:ring-red-400",
  info: "bg-blue-600 hover:bg-blue-500 text-white focus:ring-blue-400",
  success: "bg-green-600 hover:bg-green-500 text-white focus:ring-green-400",
  delete: "bg-[#F5F5DC] hover:bg-[#EDEAD0] text-red-600 focus:ring-[#EDEAD0]",
};

/**
 * Size classes:
 * - We fix the height to h-8 (~32px), or h-12 (~48px) if you prefer
 * - Increase the width for sm, md, lg,
 * - For 'full', we stretch the width to 100%.
 */
const sizeClasses = {
  sm: "h-12 w-24 text-sm",
  md: "h-10 w-32 text-base",
  lg: "h-12 w-36 text-base",
  xl: "h-12 w-52 text-lg",
  full: "w-full h-12 text-base",
};

/**
 * Utility to merge any provided overrides with the base variant styles.
 */
function buildButtonClasses(
  variant: ButtonVariant,
  overrides?: ColorOverrides
) {
  if (!overrides) {
    return variantClasses[variant];
  }

  const { bgColor, hoverBgColor, focusRingColor, textColor, textSize } =
    overrides;

  // Combine the override classes (if provided), ignoring undefined
  return classNames(bgColor, hoverBgColor, focusRingColor, textColor, textSize);
}

/**
 * A reusable base Button component.
 * - Always centers itself horizontally (via `block mx-auto`).
 * - Maintains a fixed height for sm, md, lg, but different widths.
 * - Accepts a parent’s `disabled` prop to disable the button if needed.
 * - Supports a `text` prop for simpler usage without children.
 */
export const Button: React.FC<ButtonProps> = ({
  children,
  variant = "primary",
  colorOverrides,
  size = "md",
  disabled,
  className,
  text,
  textSize = "base",
  fontWeight = "bold",
  customIcon: CustomIcon,
  ...rest
}) => {
  // If no overrides are provided, use the variant style.
  // If overrides are provided, they take precedence.
  const finalColorClass = colorOverrides
    ? buildButtonClasses(variant, colorOverrides)
    : variantClasses[variant];

  // Determine the text content
  const content = text ? (
    <span
      className={classNames(
        "text-center font-bold", // Default styles
        {
          [`text-${textSize}`]: textSize,
          [`font-${fontWeight}`]: fontWeight,
        }
      )}
    >
      {text}
    </span>
  ) : (
    children
  );

  return (
    <button
      disabled={disabled}
      className={classNames(
        // 1) block => makes the button occupy the full width of its parent
        // 2) mx-auto => centers horizontally if the parent is wider
        "block mx-auto",

        // Common utility classes
        "border border-transparent font-medium rounded-md shadow-sm",
        "focus:outline-none focus:ring-2 focus:ring-offset-2",
        // Replace 'inline-flex' with 'flex' so it remains a block-level element
        "flex items-center justify-center",

        finalColorClass,
        sizeClasses[size],
        {
          "opacity-50 cursor-not-allowed": disabled,
        },
        className
      )}
      {...rest}
    >
      {CustomIcon && (
        <CustomIcon className="text-2xl pr-1 text-red" color="red" />
      )}
      {content}
    </button>
  );
};
