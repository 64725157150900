import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import FoodLoadingSpinner from "./components/Spinner/FoodLoadingSpinner";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import Footer from "components/Footer/Footer";
import NavBar from "components/Navbar/NavBar";

const RootLayout: React.FC = () => {
  return (
    <ErrorBoundary>
      <div className="flex flex-col min-h-screen">
        <NavBar />
        <main className="flex-grow">
          <Suspense fallback={<FoodLoadingSpinner />}>
            <Outlet />
          </Suspense>
        </main>
        <Footer />
      </div>
    </ErrorBoundary>
  );
};

export default RootLayout;
