import { get, post, put } from "../api";
import { ApiResponse, BaseResponse } from "../api/types/ApiResponse";
import { ApiHandler } from "./ApiHandler";
import { Result } from "./types/Result";

export interface CreateOrderResponse extends BaseResponse {
  payload: number;
}

export interface OrdersDetailResponseResponse extends BaseResponse {
  requestId: string | null;
  timeStamp: string | null;
  payload?: OrderDetails;
}

export interface SucessResponse extends BaseResponse {
  payload: string;
}

export interface CompletedOrdersByDateResponse extends BaseResponse {
  payload: OrderDetails[];
}

export interface CreatedOrdersByDateResponse extends BaseResponse {
  payload: OrderDetails[];
}

export interface CartItem {
  id: number;
  name: string;
  price: number;
  quantity: any;
  category_name: string;
  totalPrice: number;
  priceInKg: number;
  unitInKg: number;
}

export interface PaymentInfo {
  paymentType: string;
  debitAmount: number;
  creditAmount: number;
  totalAmount: number;
  discountAmount: number;
  subTotal: number;
  paymentStatus: string;
}

export interface OrderDetails {
  orderId: number;
  order_date: string;
  orderItemDetails: OrderItem[];
  selectedTable: string;
  status: string;
  discountAmount: number;
  subTotal: number;
  totalAmount: number;
  paymentType: string;
  eventTimeStamp: EventTimeStamps;
}

export interface OrderItem {
  imageUrl?: string;
  orderItemId: number;
  productName: string;
  quantity: number;
  price: number;
  priceInKg?: number;
  unitInKg?: number;
  createdAt: string;
  updatedAt: string;
  totalPrice: number;
}

export interface PrepCartItemDetail {
  orderId: number;
  cartItems: OrderItem[];
  selectedTable: string;
  discountAmount: number;
  subTotal: number;
  totalAmount: number;
  paymentInfo: PaymentInfo;
}

export interface EventTimeStamps {
  createdDate: string;
  createdTime: string;
  completedDate: string;
  completedTime: string;
}

export const initialPrepCartItemDetail: PrepCartItemDetail = {
  orderId: 0,
  cartItems: [],
  selectedTable: "",
  discountAmount: 0,
  subTotal: 0,
  totalAmount: 0,
  paymentInfo: {} as PaymentInfo,
};

export const createOrderApi = async (
  cartItemDetails: PrepCartItemDetail,
  id?: number
): Promise<Result<CreateOrderResponse>> => {
  try {
    const orderItems = cartItemDetails.cartItems.map((item) => ({
      food_id: item.orderItemId,
      product_name: item.productName,
      quantity: item.quantity,
      price: item.price,
      priceInKg: item.priceInKg || 0,
      unitInKg: item.unitInKg || 0,
    }));

    // TODO: set correct Staff Name
    const order = {
      total_amount: cartItemDetails.subTotal,
      seat_name: cartItemDetails.selectedTable,
      order_items: orderItems,
      user_id: id || 0,
    };

    const response: ApiResponse<CreateOrderResponse> =
      await post<CreateOrderResponse>(`/api/orders`, order);
    return ApiHandler.handleApiResponse<CreateOrderResponse>(response);
  } catch (error: any) {
    return ApiHandler.handleApiError(error);
  }
};

export const completeOrderApi = async (
  paymentInfo: PaymentInfo,
  orderId: number
): Promise<Result<SucessResponse>> => {
  try {
    const payload = {
      payment_info: paymentInfo,
      order_id: orderId,
    };

    const response: ApiResponse<SucessResponse> = await post<SucessResponse>(
      `/api/orders/complete`,
      payload
    );
    return ApiHandler.handleApiResponse<SucessResponse>(response);
  } catch (error: any) {
    return ApiHandler.handleApiError(error);
  }
};

export const updateOrderApi = async (
  orderId: number,
  orderItemId: number,
  quantity: number,
  productName: string,
  updateAction: string,
  priceInKg: number,
  unitInKg: number
): Promise<Result<SucessResponse>> => {
  try {
    const payload = {
      order_id: orderId,
      order_item_id: orderItemId,
      quantity: quantity,
      product_name: productName,
      priceInKg: priceInKg,
      unitunitInKgInKg: unitInKg,
    };

    const response: ApiResponse<SucessResponse> = await put<SucessResponse>(
      `/api/orders/quantity?action=${updateAction}`,
      payload
    );
    return ApiHandler.handleApiResponse<SucessResponse>(response);
  } catch (error: any) {
    return ApiHandler.handleApiError(error);
  }
};

export const GetOrdersByTableAndStatusApi = async (
  seatName: string,
  orderStatus: string
): Promise<Result<OrdersDetailResponseResponse>> => {
  try {
    const response: ApiResponse<OrdersDetailResponseResponse> =
      await get<OrdersDetailResponseResponse>(
        `/api/orders/status?seatName=${seatName}&status=${orderStatus}`
      );
    return ApiHandler.handleApiResponse<OrdersDetailResponseResponse>(response);
  } catch (error: any) {
    return ApiHandler.handleApiError(error);
  }
};

export const GetOrdersByDateRangeAndStatusApi = async (
  orderStatuses: string[],
  date: string
): Promise<Result<CompletedOrdersByDateResponse>> => {
  try {
    // Construct query parameters
    const queryParams = new URLSearchParams();
    queryParams.append("date", date);
    orderStatuses.forEach((status) => queryParams.append("statuses", status));

    // Perform the API call
    const response: ApiResponse<CompletedOrdersByDateResponse> =
      await get<CompletedOrdersByDateResponse>(
        `/api/orders/dateRange?${queryParams.toString()}`
      );

    // Handle the API response
    return ApiHandler.handleApiResponse<CompletedOrdersByDateResponse>(
      response
    );
  } catch (error: any) {
    // Handle any errors during the API call
    return ApiHandler.handleApiError(error);
  }
};
