import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from "react";
import {
  GetOrdersByTableAndStatusApi,
  initialPrepCartItemDetail,
  OrderDetails,
  OrderItem,
  OrdersDetailResponseResponse,
  PaymentInfo,
  PrepCartItemDetail,
  SucessResponse,
  updateOrderApi,
} from "service/orderService";
import { checkQuantityAction, isValidNumber } from "util/DataHelpers";

import { Result } from "service/types/Result";
import { StateType } from "enum/StateType";
import { useFoodMenuContext } from "./FoodMenuContext";
import { useSeatContext } from "./SeatContext";
import { useNavigation } from "hooks/navigation/navigation";
import OrderStatus from "util/OrderStatus";

interface CartContextType {
  addToCartAction: (productName: string, qty: number, unitInKg: number) => void;
  qtyUpdateActionHandler: (
    updateQty: number,
    itemName: string,
    itemId: number,
    updatedUnitInKg: number
  ) => void;
  resetPrepCartItemDetail: () => void;
  resetExistingOrdersStateBySeat: (seatName: string) => void;
  GetOrdersByTableAndStatus: (
    seatName: string,
    redirectTo: "menu" | "cart" | "none"
  ) => Promise<void>;
  existingOrdersStateBySeat: Map<string, StateType>;
  updateItemStateByItemId: Map<number, StateType>;
  setPrepCartItemDetail: React.Dispatch<
    React.SetStateAction<PrepCartItemDetail>
  >;
  prepCartItemDetail: PrepCartItemDetail;
  resetExistingOrdersState: () => void;
}

const CartContext = createContext<CartContextType>({
  addToCartAction: () => {},
  qtyUpdateActionHandler: () => {},
  resetPrepCartItemDetail: () => {},
  resetExistingOrdersStateBySeat: () => {},
  setPrepCartItemDetail: () => {},
  GetOrdersByTableAndStatus: async () => {},
  existingOrdersStateBySeat: {} as Map<string, StateType>,
  prepCartItemDetail: {} as PrepCartItemDetail,
  updateItemStateByItemId: {} as Map<number, StateType>,
  resetExistingOrdersState: () => {},
});

export const CartProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { foods } = useFoodMenuContext();
  const { seatName } = useSeatContext();
  const { goToMenu, goToCart } = useNavigation();

  const [existingOrdersStateBySeat, setExistingOrdersStateBySeat] = useState<
    Map<string, StateType>
  >(new Map());

  const [updateItemStateByItemId, setUpdateItemStateByItemId] = useState<
    Map<number, StateType>
  >(new Map());

  const [prepCartItemDetail, setPrepCartItemDetail] =
    useState<PrepCartItemDetail>(initialPrepCartItemDetail);

  // Ref to keep track of existingOrdersStateBySeat
  const existingOrdersStateBySeatRef = useRef(existingOrdersStateBySeat);

  // Update the ref whenever existingOrdersStateBySeat changes
  useEffect(() => {
    existingOrdersStateBySeatRef.current = existingOrdersStateBySeat;
  }, [existingOrdersStateBySeat]);

  const resetPrepCartItemDetail = () => {
    setPrepCartItemDetail(initialPrepCartItemDetail);
  };

  const calculateTotalAmount = useCallback((cartItems: OrderItem[]) => {
    return cartItems.reduce((total, item) => {
      if ((item.unitInKg || 0) > 0) {
        total += (item.unitInKg || 0) * (item.priceInKg || 0);
      }
      if (item.quantity > 0) {
        total += item.price * item.quantity;
      }
      return total;
    }, 0);
  }, []);

  const addItemToLocalCart = useCallback(
    (productName: string, qty: number, unitInKg: number) => {
      setPrepCartItemDetail((prev) => {
        const currentCartItems = prev.cartItems;

        let updatedCartItems: OrderItem[];

        const {
          price = 0,
          price_kg = 0,
          id = 0,
        } = foods.find((food) => food.name === productName) || {};

        const existingQtyItem = currentCartItems.find(
          (item) => item.productName === productName && qty > 0
        );
        const existingUnitInKgItem = currentCartItems.find(
          (item) => item.productName === productName && unitInKg > 0
        );

        if (unitInKg !== undefined && unitInKg > 0) {
          // Case: Add by unitInKg
          if (existingUnitInKgItem) {
            // Update existing item with unitInKg
            updatedCartItems = currentCartItems.map((item) =>
              item.productName === productName && item.unitInKg !== 0
                ? { ...item, unitInKg: (item.unitInKg || 0) + unitInKg }
                : item
            );
          } else {
            // Add new entry for unitInKg
            updatedCartItems = [
              ...currentCartItems,
              {
                orderItemId: id,
                productName: productName,
                quantity: 0,
                price: price,
                priceInKg: price_kg,
                unitInKg: unitInKg,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
              } as OrderItem,
            ];
          }
        } else {
          // Case: Add by quantity
          if (existingQtyItem) {
            // Update existing item with quantity
            updatedCartItems = currentCartItems.map((item) =>
              item.productName === productName && item.unitInKg === 0
                ? { ...item, quantity: item.quantity + 1 }
                : item
            );
          } else {
            // Add new entry for quantity
            updatedCartItems = [
              ...currentCartItems,
              {
                orderItemId: id,
                productName: productName,
                quantity: 1,
                price: price,
                priceInKg: price_kg,
                unitInKg: 0,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
              } as OrderItem,
            ];
          }
        }

        const updatedTotalAmount = calculateTotalAmount(updatedCartItems);
        return {
          ...prev,
          selectedTable: seatName,
          cartItems: updatedCartItems,
          totalAmount: updatedTotalAmount,
          subTotal: updatedTotalAmount,
        };
      });
    },
    [calculateTotalAmount, foods, seatName]
  );

  const removeAddQtyToLocalCartItems = useCallback(
    (updatedQty: number, itemName: string) => {
      setPrepCartItemDetail((prev) => {
        const currentCartItems = prev.cartItems;

        let newCartItems: OrderItem[] = [];

        if (updatedQty === 0) {
          // Remove the item from the cart
          newCartItems = currentCartItems.filter(
            (item) => item.productName !== itemName
          );
        } else {
          // Update the quantity and price of the item
          newCartItems = currentCartItems.map((item) => {
            if (item.productName === itemName) {
              return {
                ...item,
                quantity: updatedQty,
                updatedAt: new Date().toISOString(),
              } as OrderItem;
            }
            return item;
          });
        }

        const updatedTotalAmount = calculateTotalAmount(newCartItems);

        return {
          ...prev,
          selectedTable: seatName,
          cartItems: newCartItems,
          totalAmount: updatedTotalAmount,
          subTotal: updatedTotalAmount,
        };
      });
    },
    [calculateTotalAmount, seatName]
  );

  // Handle Quantity Update
  const qtyUpdateActionHandler = (
    updatedQty: number,
    itemName: string,
    itemId: number,
    updatedUnitInKg: number
  ) => {
    const { orderId, selectedTable } = prepCartItemDetail;

    // if valid orderId then, it means it's pending order so we make api call for update
    if (isValidNumber(orderId)) {
      const updateAction = checkQuantityAction(updatedQty);

      const { priceInKg = 0 } =
        prepCartItemDetail.cartItems.find(
          (item) => item.productName === itemName
        ) || {};

      updateOrderApiCall(
        orderId,
        itemId,
        updatedQty,
        updateAction,
        updatedUnitInKg,
        itemName,
        priceInKg,
        selectedTable
      );
    } else {
      removeAddQtyToLocalCartItems(updatedQty, itemName);
    }
  };

  // Handle Add to Cart with Order Update
  const addToCartAction = (
    productName: string,
    newQty: number,
    newUnitInKg: number
  ) => {
    const orderId = prepCartItemDetail.orderId;

    // if valid orderId then, it means it's pending order so we make api call for update/add
    if (isValidNumber(orderId)) {
      const { orderId, selectedTable } = prepCartItemDetail;
      const { orderItemId = 0, priceInKg = 0 } =
        prepCartItemDetail.cartItems.find(
          (item) => item.productName === productName
        ) || {};

      if (newUnitInKg === 0 || newUnitInKg === undefined) {
        updateOrderApiCall(
          orderId,
          orderItemId,
          newQty,
          "add",
          0,
          productName,
          priceInKg,
          selectedTable
        );
      } else {
        updateOrderApiCall(
          orderId,
          orderItemId,
          0,
          "addByUnit",
          newUnitInKg,
          productName,
          priceInKg,
          selectedTable
        );
      }
    } else {
      addItemToLocalCart(productName, newQty, newUnitInKg);
    }
  };

  // Function to update state by seat
  const updateOrderStateBySeat = useCallback(
    (seatName: string, state: StateType): void => {
      setExistingOrdersStateBySeat((prevState) => {
        const updatedMap = new Map(prevState);
        updatedMap.set(seatName, state);
        return updatedMap;
      });
    },
    []
  );

  // Function to calculate subtotal
  const calculateSubTotal = useCallback((orderItems: OrderItem[]) => {
    return orderItems.reduce((acc, item) => {
      let itemSubtotal = 0;
      if ((item.unitInKg || 0) > 0) {
        itemSubtotal += (item.unitInKg || 0) * (item.priceInKg || 0);
      }
      if (item.quantity > 0) {
        itemSubtotal += item.price * item.quantity;
      }
      return acc + itemSubtotal;
    }, 0);
  }, []);

  // Function to map order details to PrepCartItemDetail
  const toPrepCartItemDetails = useCallback(
    (orderDetails: OrderDetails, seatName: string): PrepCartItemDetail => {
      const { orderItemDetails, orderId } = orderDetails;
      const subTotal = calculateSubTotal(orderItemDetails);

      return {
        orderId: orderId,
        cartItems: orderItemDetails,
        selectedTable: seatName,
        discountAmount: 0,
        subTotal: subTotal,
        totalAmount: subTotal,
        paymentInfo: {
          paymentType: "",
          debitAmount: 0,
          creditAmount: 0,
          totalAmount: 0,
          discountAmount: 0,
          subTotal: 0,
          paymentStatus: "",
        } as PaymentInfo,
      };
    },
    [calculateSubTotal]
  );

  // Function to fetch and set existing orders by seat
  const GetOrdersByTableAndStatus = useCallback(
    async (seatName: string, redirectTo: "menu" | "cart" | "none") => {
      try {
        updateOrderStateBySeat(seatName, StateType.Loading);

        const result: Result<OrdersDetailResponseResponse, string> =
          await GetOrdersByTableAndStatusApi(seatName, OrderStatus.Pending);

        switch (result.type) {
          case "success":
            const response = result.data.payload;

            if (response && response.orderItemDetails !== null) {
              const prepCartDetail = toPrepCartItemDetails(
                response as OrderDetails,
                seatName
              );
              setPrepCartItemDetail((prev) => ({
                ...prev,
                ...prepCartDetail,
              }));
            }

            updateOrderStateBySeat(seatName, StateType.Success);

            // Navigate based on the target
            if (redirectTo === "menu") {
              goToMenu(seatName);
            } else if (redirectTo === "cart") {
              goToCart();
            }

            break;
          case "failure":
            updateOrderStateBySeat(seatName, StateType.Failure);
            break;
          default:
            updateOrderStateBySeat(seatName, StateType.Failure);
        }
      } catch (error) {
        console.error("Error fetching existing orders by seat:", error);
        updateOrderStateBySeat(seatName, StateType.Failure);
      }
    },
    [toPrepCartItemDetails, updateOrderStateBySeat, goToMenu, goToCart]
  );

  // Function to update state by itemId
  const updateItemUpdateStateByItemId = useCallback(
    (itemId: number, state: StateType): void => {
      setUpdateItemStateByItemId((prevState) => {
        const updatedMap = new Map(prevState);
        updatedMap.set(itemId, state);
        return updatedMap;
      });
    },
    []
  );

  // Helper function to wait for existingOrdersStateBySeat[seatName] to be Success or Failure
  const waitForExistingOrdersStateBySeatToBeSuccess = useCallback(
    (seatName: string): Promise<StateType> => {
      return new Promise<StateType>((resolve) => {
        const checkState = () => {
          const state = existingOrdersStateBySeatRef.current.get(seatName);
          if (state === StateType.Success || state === StateType.Failure) {
            resolve(state);
          } else {
            // State is Loading or Idle, check again after a short delay
            setTimeout(checkState, 50);
          }
        };
        checkState();
      });
    },
    []
  );

  // updateOrderApiCall
  const updateOrderApiCall = useCallback(
    async (
      orderId: number,
      orderItemId: number,
      updatedQuantity: number,
      updateAction: string,
      updatedUnitInKg: number,
      itemProductName: string,
      priceInKg: number,
      seatName: string
    ) => {
      // Set item state to Loading
      updateItemUpdateStateByItemId(orderItemId, StateType.Loading);

      try {
        // Call updateOrderApi
        const result: Result<SucessResponse, string> = await updateOrderApi(
          orderId,
          orderItemId,
          updatedQuantity,
          itemProductName,
          updateAction,
          priceInKg,
          updatedUnitInKg
        );

        if (result.type === "success") {
          // Fetch updated order details, makes Api Call
          GetOrdersByTableAndStatus(seatName, "none");

          // Wait for existingOrdersStateBySeat[seatName] to become Success or Failure
          const state =
            await waitForExistingOrdersStateBySeatToBeSuccess(seatName);

          // Check the state explicitly
          if (state === StateType.Success) {
            // After successful fetch, update item state to Success
            updateItemUpdateStateByItemId(orderItemId, StateType.Success);
          } else {
            // If fetching existing orders failed, update item state to Failure
            updateItemUpdateStateByItemId(orderItemId, StateType.Failure);
          }
        } else {
          // On failure of updateOrderApi, update item state to Failure
          updateItemUpdateStateByItemId(orderItemId, StateType.Failure);
        }
      } catch (error) {
        // Handle any errors during the updateOrderApi call
        console.error("Error updating order:", error);
        updateItemUpdateStateByItemId(orderItemId, StateType.Failure);
      }
    },
    [
      updateItemUpdateStateByItemId,
      // prepCartItemDetail.selectedTable,
      GetOrdersByTableAndStatus,
      waitForExistingOrdersStateBySeatToBeSuccess,
    ]
  );

  const resetExistingOrdersStateBySeat = useCallback(
    (seatName: string) => {
      updateOrderStateBySeat(seatName, StateType.Idle);
    },
    [updateOrderStateBySeat]
  );

  const resetExistingOrdersState = useCallback(() => {
    setExistingOrdersStateBySeat(new Map());
  }, [setExistingOrdersStateBySeat]);

  return (
    <CartContext.Provider
      value={{
        prepCartItemDetail,
        addToCartAction,
        qtyUpdateActionHandler,
        resetPrepCartItemDetail,
        resetExistingOrdersStateBySeat,
        setPrepCartItemDetail,
        GetOrdersByTableAndStatus,
        existingOrdersStateBySeat,
        updateItemStateByItemId,
        resetExistingOrdersState,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCartContext = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};
