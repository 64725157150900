import React from "react";
import footerBg from "../../Images/cafeImages/logo.jpeg";

const Footer: React.FC = () => {
  return (
    <footer
      className="w-full text-black py-10 bg-[#eedaba] bg-cover bg-no-repeat"
      style={{
        backgroundImage: `url(${footerBg})`,
      }}
    >
      {/* Content Container */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* First Row: Opening Hours, Contact Us, Follow Us */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
          {/* Opening Hours */}
          <div>
            <h5 className="text-lg font-bold mb-4">Opening Hours</h5>
            <p className="mb-2">Monday - Friday: 7:00 AM - 8:00 PM</p>
            <p>Saturday - Sunday: 7:00 AM - 8:00 PM</p>
          </div>

          {/* Contact Us */}
          <div>
            <h5 className="text-lg font-bold mb-4">Contact Us</h5>
            <p className="mb-2">
              Email:{" "}
              <a
                href="mailto:info@laxmimithai.com"
                className="text-black underline"
              >
                info@laxmimithai.com
              </a>
            </p>
            <p className="mb-2">Phone: 01-6611118 / 01-6610165</p>
            <p>Address: Suryabinayak, Bhaktapur 44800, Nepal</p>
          </div>

          {/* Follow Us */}
          <div>
            <h5 className="text-lg font-bold mb-4">Follow Us</h5>
            <div className="flex justify-center">
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-black mx-2 hover:text-eedabaDark transition-colors duration-300"
                aria-label="Facebook"
              >
                <i className="fab fa-facebook-f text-xl"></i>
              </a>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-black mx-2 hover:text-eedabaDark transition-colors duration-300"
                aria-label="Twitter"
              >
                <i className="fab fa-twitter text-xl"></i>
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-black mx-2 hover:text-eedabaDark transition-colors duration-300"
                aria-label="Instagram"
              >
                <i className="fab fa-instagram text-xl"></i>
              </a>
              <a
                href="https://linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-black mx-2 hover:text-eedabaDark transition-colors duration-300"
                aria-label="LinkedIn"
              >
                <i className="fab fa-linkedin-in text-xl"></i>
              </a>
            </div>
          </div>
        </div>

        {/* Divider */}
        <div className="my-8 border-t border-gray-300"></div>

        {/* Second Row: Copyright */}
        <div className="text-center">
          <p className="text-sm">
            &copy; {new Date().getFullYear()} Laxmi Mithai. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
