import { get } from "../api";
import { ApiResponse, BaseResponse } from "../api/types/ApiResponse";
import { ApiHandler } from "./ApiHandler";
import { Result } from "./types/Result";

export interface GetAllSeatsResponse extends BaseResponse {
  payload: string[];
}

export const fetchTables = async (): Promise<Result<GetAllSeatsResponse>> => {
  try {
    const response: ApiResponse<GetAllSeatsResponse> =
      await get<GetAllSeatsResponse>(`/api/seats`);
    return ApiHandler.handleApiResponse<GetAllSeatsResponse>(response);
  } catch (error: any) {
    return ApiHandler.handleApiError(error);
  }
};
