import React, { useCallback, useEffect } from "react";
import CartItemComponent from "./CartItem";
import { StateType } from "../../enum/StateType";
import { OrderItem } from "service/orderService";
import EmptyCartMessageBox from "../Utils/Message/EmptyCartMessageBox";
import PrimaryTitle from "../PrimaryTitle";
import { LoadingButton } from "components/Button";

interface CartBoxProps {
  handleQtyUpdateAction: (
    updateQty: number,
    itemName: string,
    itemId: number,
    updatedUnitInKg: number
  ) => void;
  cartItems: OrderItem[];
  totalAmount: number;
  onPlaceOrderClick: () => void;
  seatname: string;
  updateItemStateByItemId: Map<number, StateType>;
  createOrderState: StateType;
  showMessageModel: boolean;
  handleOnCloseModelAction: () => void;
  createOrderSuccessHandler: (seatName: string) => void;
  orderId: number;
  isCartDropDown?: boolean;
  margin?: string;
  padding?: string;
}

const CartBox: React.FC<CartBoxProps> = ({
  handleQtyUpdateAction,
  cartItems,
  totalAmount,
  onPlaceOrderClick,
  seatname,
  updateItemStateByItemId,
  createOrderSuccessHandler,
  createOrderState,
  orderId,
  isCartDropDown = false,
  margin = "mt-4",
  padding = "p-4",
}) => {
  const handleQtyUpdate = (
    updatedQty: number,
    itemName: string,
    itemId: number,
    unitInKg: number = 0
  ) => {
    handleQtyUpdateAction(updatedQty, itemName, itemId, unitInKg);
  };

  const incrementCount = (count: number, itemName: string, itemId: number) => {
    handleQtyUpdate(count + 1, itemName, itemId);
  };

  const decrementCount = (count: number, itemName: string, itemId: number) => {
    if (count > 0) {
      handleQtyUpdate(count - 1, itemName, itemId);
    }
  };

  const getUpdatedItemState = useCallback(
    (itemId: number) => {
      // Retrieve the state for the selected seat
      return updateItemStateByItemId?.get(itemId) || StateType.Idle;
    },
    [updateItemStateByItemId]
  );

  useEffect(() => {
    if (createOrderState === StateType.Success) {
      createOrderSuccessHandler(seatname);
    }
  }, [createOrderState, createOrderSuccessHandler, seatname]);

  return (
    <div className="w-full">
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <PrimaryTitle
          titleName={orderId}
          seatName={seatname}
          bgColor="bg-secondary-bg"
          borderColor="border-mocha"
          borderWidth="border-2"
          rounded={true}
          textColor="text-black-800"
          shadow="shadow-sm"
          showBorder={false}
          searchEnabled={false}
          animateLine={false}
        />

        <div className={`${padding}`}>
          {cartItems.length === 0 ? (
            <EmptyCartMessageBox />
          ) : (
            <>
              <p className="text-gray-600 mb-4">ग्राहकको सामानहरु:</p>
              {isCartDropDown ? (
                <ul className="space-y-2">
                  {cartItems.map((item) => (
                    <li
                      key={item.orderItemId}
                      className="flex justify-between items-center border-b border-gray-200 py-2"
                    >
                      <span className="text-gray-800 text-sm">
                        {item.productName} - {item.quantity} x $
                        {item.price.toFixed(2)}
                      </span>
                    </li>
                  ))}
                  <li className="flex justify-between font-semibold mt-4">
                    <span>Total</span>
                    <span>${totalAmount.toFixed(2)}</span>
                  </li>
                </ul>
              ) : (
                <div className="overflow-x-auto mb-2">
                  <table className="min-w-full bg-white">
                    <thead className="bg-gray-200">
                      <tr>
                        <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">
                          Name
                        </th>
                        <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">
                          Price
                        </th>
                        <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">
                          Quantity
                        </th>
                        <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {cartItems.map((item) => (
                        <CartItemComponent
                          key={item.orderItemId + item.productName}
                          item={item}
                          onIncrement={incrementCount}
                          onDecrement={decrementCount}
                          cartItemState={getUpdatedItemState(item.orderItemId)}
                          isCartDropDown={isCartDropDown}
                        />
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th
                          colSpan={3}
                          className="py-3 px-6 text-left text-sm font-medium text-gray-700"
                        >
                          Total
                        </th>
                        <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">
                          ${totalAmount.toFixed(2)}
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              )}

              {!isCartDropDown && (
                <LoadingButton
                  type="submit"
                  buttonState={createOrderState}
                  text="CREATE ORDER"
                  size="full"
                  error={null}
                  onClick={onPlaceOrderClick}
                  disabled={orderId === undefined || orderId > 0}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CartBox;
