import React from "react";
import { OrderItem } from "service/orderService";
import SecondaryCartItem from "./SecondaryCartItem";

interface CartItemsListProps {
  cartItems: OrderItem[];
  onIncrement: (count: number, itemName: string, itemId: number) => void;
  onDecrement: (count: number, itemName: string, itemId: number) => void;
}

const CartItemsList: React.FC<CartItemsListProps> = ({
  cartItems,
  onIncrement,
  onDecrement,
}) => {
  return (
    <div className="px-6 py-2">
      <table className="w-full table-auto border-collapse text-left">
        <thead>
          <tr className="text-sm text-gray-600">
            <th className="py-2 font-semibold">Item Name</th>
            <th className="py-2 pl-6 font-semibold">Quantity</th>
            <th className="py-2 font-semibold">Price</th>
            <th className="py-2 font-semibold">Total</th>
          </tr>
        </thead>
        <tbody>
          {cartItems.map((item) => (
            <SecondaryCartItem
              key={item.orderItemId}
              item={item}
              onIncrement={onIncrement}
              onDecrement={onDecrement}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CartItemsList;
