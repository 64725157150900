import { PATHS } from "constants/router/routes";
import { IconType } from "react-icons";
import { RiAccountCircleFill, RiAdminFill } from "react-icons/ri";
import { IoHome, IoContrastOutline } from "react-icons/io5";
import { TbLayoutDashboardFilled } from "react-icons/tb";
import { MdMenuBook, MdFoodBank, MdSecurityUpdateGood } from "react-icons/md";
import { PiChairFill, PiUsersFourFill, PiNotebookFill } from "react-icons/pi";
import logo from "../../Images/cafeImages/logo.jpeg";
import { FaEllipsisV, FaShoppingCart } from "react-icons/fa";

export interface NavBarProperties {
  leftSection: NavBarItem[];
  centralSection: NavBarItem[];
  rightSection: NavBarItem[];
}

export interface NavBarItem {
  title: string;
  route?: string;
  hasDropdown?: boolean;
  dropdownItems?: NavBarItem[];
  isDisabled?: boolean;
  showTextWithIcon?: boolean;
  showHoverText?: boolean;
  iconType?: IconType;
  image?: {
    src: string;
    alt: string;
    className?: string;
  };
  isUnderLineEffectDisabled?: boolean;
}

// Modify getNavItems to accept `t` function
export const getNavItems = (
  isAuthenticated: boolean = false,
  t: (key: string) => string // Pass the translation function
): NavBarProperties => {
  const leftSection = [
    {
      title: t("nav.laxmiMithai"),
      route: PATHS.HOME,
      isDisabled: false,
      showHoverText: false,
      showTextWithIcon: true,
      isUnderLineEffectDisabled: true,
      image: {
        src: logo,
        alt: t("nav.laxmiMithaiLogo"),
        className: "h-12 w-12 mr-3 object-cover rounded-full",
      },
    },
  ];

  const centralSection = [
    {
      title: t("nav.home"),
      route: PATHS.HOME,
      isDisabled: false,
      showHoverText: true,
      iconType: IoHome,
    },
    {
      title: t("nav.menu"),
      route: PATHS.ORDER_MENU,
      isDisabled: false,
      showHoverText: true,
      iconType: MdFoodBank,
    },
    {
      title: t("nav.seat"),
      route: PATHS.TABLE,
      isDisabled: !isAuthenticated,
      showHoverText: true,
      iconType: PiChairFill,
    },
    {
      title: t("nav.orderDetails"),
      isDisabled: false,
      showHoverText: true,
      route: PATHS.ORDER_DETAILS,
      iconType: PiNotebookFill,
    },
  ];

  const rightSection: NavBarItem[] = [];

  // Always include Cart
  rightSection.push({
    title: t("nav.cart"),
    route: PATHS.CART,
    showTextWithIcon: true,
    isDisabled: !isAuthenticated,
    showHoverText: false,
    iconType: FaShoppingCart,
  });

  if (!isAuthenticated) {
    // Show Sign In
    rightSection.unshift({
      title: t("nav.signIn"),
      route: PATHS.LOG_IN,
      isDisabled: false,
      showTextWithIcon: true,
      showHoverText: false,
      iconType: RiAccountCircleFill,
    });
  } else {
    // Show More Dropdown
    rightSection.unshift({
      title: t("nav.more"),
      hasDropdown: true,
      showTextWithIcon: true,
      isDisabled: false,
      isUnderLineEffectDisabled: true,
      iconType: FaEllipsisV,
      dropdownItems: [
        {
          title: t("nav.more.menu"),
          route: PATHS.ORDER_MENU,
          isDisabled: false,
          isUnderLineEffectDisabled: true,
          showTextWithIcon: true,
          iconType: MdMenuBook,
        },
        {
          title: t("nav.more.dashboard"),
          route: PATHS.DASHBOARD,
          isDisabled: false,
          showHoverText: true,
          isUnderLineEffectDisabled: true,
          iconType: TbLayoutDashboardFilled,
        },
        {
          title: t("nav.more.qrMenu"),
          route: PATHS.VIEWMENU,
          isDisabled: false,
          showHoverText: true,
          isUnderLineEffectDisabled: true,
          iconType: MdFoodBank,
        },
        {
          title: t("nav.more.admin"),
          hasDropdown: true,
          showTextWithIcon: true,
          isDisabled: false,
          isUnderLineEffectDisabled: true,
          iconType: RiAdminFill,
          dropdownItems: [
            {
              title: t("nav.more.admin.userManagement"),
              isDisabled: false,
              iconType: PiUsersFourFill,
            },
            {
              title: t("nav.more.admin.menuManager"),
              route: PATHS.MENU_MANAGER,
              isDisabled: false,
              iconType: MdSecurityUpdateGood,
            },
          ],
        },
        {
          title: t("nav.more.expenses"),
          hasDropdown: true,
          isDisabled: false,
          showTextWithIcon: true,
          isUnderLineEffectDisabled: true,
          iconType: IoContrastOutline,
          dropdownItems: [
            {
              title: t("nav.more.expenses.reports"),
              route: "/expenses/reports",
              isUnderLineEffectDisabled: true,
              isDisabled: false,
            },
            {
              title: t("nav.more.expenses.analytics"),
              route: "/expenses/analytics",
              isDisabled: false,
            },
          ],
        },
        {
          title: t("nav.more.logOut"),
          route: PATHS.HOME,
          isDisabled: false,
          showTextWithIcon: true,
          isUnderLineEffectDisabled: true,
          showHoverText: false,
          iconType: RiAccountCircleFill,
        },
      ],
    });
  }

  return {
    leftSection,
    centralSection,
    rightSection,
  };
};

// Modify getNavItemsForMobileView to accept `t` function
export const getNavItemsForMobileView = (
  isAuthenticated: boolean = false,
  t: (key: string) => string // Pass the translation function
): NavBarItem[] => {
  const allNavItems = getNavItems(isAuthenticated, t);

  // Define the titles you want to include in the mobile view, translated
  const mobileViewTitles = [
    t("nav.home"),
    t("nav.more.dashboard"),
    t("nav.menu"),
    t("nav.seat"),
    t("nav.more.admin"),
    t("nav.more.expenses"),
    t("nav.more.logOut"),
  ];

  const filteredCentralItems = allNavItems.centralSection.filter((item) =>
    mobileViewTitles.includes(item.title)
  );

  const filteredRightItems = allNavItems.rightSection
    .flatMap((item) => {
      if (item.hasDropdown && item.dropdownItems) {
        // Flatten dropdown items to the same level
        return item.dropdownItems.filter((dropdownItem) =>
          mobileViewTitles.includes(dropdownItem.title)
        );
      }
      return mobileViewTitles.includes(item.title) ? [item] : [];
    })
    .filter(Boolean); // Remove undefined or empty items

  // Combine central and right filtered items
  return [...filteredCentralItems, ...filteredRightItems];
};
