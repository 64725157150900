import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
  useMemo,
} from "react";
import { ApiState, useApiState } from "../hooks/State/useApiState";
import { GetAllSeatsResponse } from "../models/ResponseModels";
import { Result } from "../service/types/Result";
import { fetchTables } from "../service/tableService";
import { StateType } from "../enum/StateType";

type SeatServiceContextType = {
  seatName: string;
  setSeatNameAction: (seatName: string) => void;
  fetchSeatTables: () => Promise<void>;
  seats: string[];
  tableState: ApiState;
  errorMsg?: string;
};

export const SeatContext = createContext<SeatServiceContextType | undefined>(
  undefined
);

interface SeatProviderProps {
  children: ReactNode;
}

export const SeatProvider: React.FC<SeatProviderProps> = ({ children }) => {
  // Custom hooks
  const tableState = useApiState();

  // local state
  const [seats, setSeats] = useState<string[]>([]);
  const [seatName, setSeatName] = useState("");

  const setSeatNameAction = useCallback(
    (seatName: string) => {
      setSeatName(seatName);
    },
    [setSeatName]
  );

  const fetchSeatTables = useCallback(async () => {
    if (tableState.state === StateType.Loading || seats.length > 0) {
      return;
    }

    tableState.loading();
    const result: Result<GetAllSeatsResponse, string> = await fetchTables();

    switch (result.type) {
      case "success":
        setSeats(result.data.payload);
        tableState.success();
        break;
      case "failure":
        tableState.failure(result.error);
        break;
      default:
        tableState.failure("An unexpected error occurred.");
    }
  }, [tableState, seats.length]);

  const value: SeatServiceContextType = useMemo(
    () => ({
      seatName,
      setSeatNameAction,
      fetchSeatTables,
      tableState: tableState,
      errorMsg: tableState.error || "",
      seats,
    }),
    [seatName, setSeatNameAction, fetchSeatTables, seats, tableState]
  );

  return <SeatContext.Provider value={value}>{children}</SeatContext.Provider>;
};

export const useSeatContext = () => {
  const context = useContext(SeatContext);
  if (!context) {
    throw new Error("useSeatContext must be used within a SeatProvider");
  }
  return context;
};
