import { createBrowserRouter } from "react-router-dom";
import RootLayout from "../../RootLayout";
import { ROUTES } from "./routes";
import ProvidersLayout from "ProvidersLayout";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <ProvidersLayout />,
    children: [
      {
        element: <RootLayout />,
        children: ROUTES,
      },
    ],
  },
]);
