import React, { useState } from "react";
import classNames from "classnames";
import SearchInput from "./SearchInput";
import { FiSearch } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";

interface PrimaryTitleProps {
  titleName: string | number;
  seatName?: string;
  padding?: string;
  margin?: string;
  bgColor?: string;
  borderColor?: string;
  rounded?: boolean;
  borderWidth?: string;
  textColor?: string;
  shadow?: string;
  animationDuration?: string;
  animateLine?: boolean; // Boolean to enable or disable animation
  showBorder?: boolean; // Boolean to show or hide border
  lineColor?: string; // Color of the animated line
  searchEnabled?: boolean; // New prop to enable or disable the search input
  searchValue?: string; // Prop to pass the search value
  onSearchChange?: (e: React.ChangeEvent<HTMLInputElement>) => void; // Prop to handle input change
  onSearchKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void; // Prop to handle key press
}

const PrimaryTitle: React.FC<PrimaryTitleProps> = ({
  titleName,
  seatName = "",
  padding = "py-4",
  margin = "",
  bgColor = "bg-secondary-bg",
  borderColor = "border-transparent",
  rounded = false,
  borderWidth = "border-1",
  textColor = "text-black",
  shadow = "shadow-md",
  animationDuration = "3s",
  animateLine = true, // Default animation enabled
  showBorder = true, // Default to showing border
  lineColor = "bg-mocha", // Default line color
  searchEnabled = false, // Default search disabled
  searchValue = "",
  onSearchChange,
  onSearchKeyPress,
}) => {
  const isTitleNumber = typeof titleName === "number";

  // State to manage mobile search toggle
  const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);

  // Toggles the mobile search input
  const handleMobileSearchToggle = () => {
    setIsMobileSearchOpen((prev) => !prev);
  };

  return (
    <div
      className={classNames(
        "relative w-full",
        padding,
        margin,
        bgColor,
        shadow,
        {
          [borderColor]: showBorder,
          [borderWidth]: showBorder,
          "rounded-lg": rounded,
        },
      )}
    >
      {/* -----------------------------
          MOBILE VIEW (block on mobile, hidden on md+)
      ----------------------------- */}
      <div className="flex items-center justify-between md:hidden relative w-full">
        {/* If there's a seatName, position it absolutely on the left */}
        {seatName && !isMobileSearchOpen && (
          <span className="absolute left-4 text-2xl font-bold text-black-700 font-arsenal italic">
            {seatName}
          </span>
        )}

        {/* Conditionally render the search input or the title on mobile */}
        {searchEnabled && isMobileSearchOpen ? (
          <div className="w-full px-4">
            <SearchInput
              value={searchValue}
              onChange={onSearchChange}
              onKeyPress={onSearchKeyPress}
              disabled={!searchEnabled}
            />
          </div>
        ) : (
          <h1
            className={classNames(
              "text-2xl font-bold font-arsenal text-center flex-1",
              textColor,
            )}
          >
            {isTitleNumber ? `Order ID: ${titleName}` : titleName}
          </h1>
        )}

        {/* Search Icon or Close Icon Toggle (on mobile) */}
        {searchEnabled && (
          <button
            type="button"
            onClick={handleMobileSearchToggle}
            className="absolute right-4 p-2"
          >
            {/* Simple example icons. Replace with your own icon library if desired */}
            {isMobileSearchOpen ? (
              <RxCross2 className="absolute right-2 bottom-2 mt-2 mr-2 ml-2 text-black" />
            ) : (
              <FiSearch className="absolute right-2 bottom-0 mr-2 ml-2 mt-2 text-black text-xl" />
            )}
          </button>
        )}
      </div>

      {/* -----------------------------
          DESKTOP VIEW (hidden on mobile, flex on md+)
      ----------------------------- */}
      <div className="hidden md:flex items-center justify-between relative w-full">
        {/* Seat name on the left (if any) */}
        {seatName && (
          <span className="absolute left-4 text-2xl font-bold text-black-700 font-arsenal italic">
            {seatName}
          </span>
        )}

        {/* Title aligned depending on if it's a number */}
        <h1
          className={classNames("text-2xl font-bold font-arsenal", textColor, {
            "absolute right-4 flex-1 text-right": isTitleNumber,
            "text-center flex-1": !isTitleNumber,
          })}
        >
          {isTitleNumber ? `Order ID: ${titleName}` : titleName}
        </h1>

        {/* Desktop search input on the right */}
        {searchEnabled && (
          <div className="absolute right-4 mt-3">
            <SearchInput
              value={searchValue}
              onChange={onSearchChange}
              onKeyPress={onSearchKeyPress}
              disabled={!searchEnabled}
            />
          </div>
        )}
      </div>

      {/* Animated line at the bottom (same for mobile/desktop if animateLine is true) */}
      {animateLine && (
        <div className="absolute bottom-0 left-0 w-full">
          <div
            className={classNames("h-1", lineColor, "animate-slide")}
            style={{
              animationDuration,
            }}
          ></div>
        </div>
      )}
    </div>
  );
};

export default PrimaryTitle;
