const APP_API_URL = process.env.REACT_APP_API_BASE_URL;

if (!APP_API_URL) {
  throw new Error(
    "REACT_APP_API_BASE_URL is not defined in the environment variables",
  );
}

const config = {
  apiUrl: APP_API_URL,
};

export default config;
