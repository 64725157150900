import { useNavigate } from "react-router-dom";
import { PATHS } from "../../constants/router/routes";

export const useNavigation = () => {
  const navigate = useNavigate();

  const goToHome = () => navigate(PATHS.HOME);
  const goToLogin = () => navigate(PATHS.LOG_IN);
  const goToViewMenu = () => navigate(PATHS.VIEWMENU);
  const goToDashboard = () => navigate(PATHS.DASHBOARD);
  const goToCart = () => navigate(PATHS.CART);
  const goToMenu = (seatName: string) =>
    navigate(PATHS.ORDER_MENU.replace(":seatName", seatName));
  const goToOrderDetails = () => navigate(PATHS.ORDER_DETAILS);
  const navigateTo = (path: string, fallback: string = PATHS.HOME) => {
    try {
      navigate(path);
    } catch (error) {
      navigate(fallback);
    }
  };
  const goBack = () => navigate(-1);

  return {
    goToHome,
    goToLogin,
    goToDashboard,
    navigateTo,
    goBack,
    goToMenu,
    goToViewMenu,
    goToCart,
    goToOrderDetails,
  };
};
